import { DesignerController } from "./DesignerController";

//WIP - not in use yet
//Generic popup interface
class DialogInterface{
    controller: DesignerController;
    alertsDIV: HTMLDivElement;
    counter = 0;

    constructor(controller: DesignerController){
        this.controller = controller;
        let alertsDIV = document.getElementById("designer_alerts");
        if(alertsDIV){
            this.alertsDIV = alertsDIV as HTMLDivElement;
        }
    }

    //Add dialog to alerts area
    addDialog(headline : string, content: string, confirmText : string = "Fortfahren", cancelText: string = "Abbrechen") : DialogModal{
        let id = "designerDialog" + this.counter;
        this.counter ++;
        let dialog = new DialogModal(id)
        dialog.headline = headline;
        dialog.content = content;
        dialog.confirmText = confirmText;
        dialog.cancelText = cancelText;

        this.alertsDIV.innerHTML += dialog.getHTML();

        let dialogDiv = document.getElementById(id) as HTMLDialogElement;
        dialog.div = dialogDiv;

        return dialog;
    }

    deleteDialog(id: string){
        let dialog = document.getElementById(id) as HTMLDialogElement;
        if(dialog){
            dialog.remove();
        }
    }

    //Show dialog modal
    openDialog(id: string){
        let dialog = document.getElementById(id) as HTMLDialogElement;
        if(dialog){
            dialog.showModal();
        }
    }

    closeDialog(id: string){
        let dialog = document.getElementById(id) as HTMLDialogElement;
        if(dialog){
            dialog.close();
        }
    }
}

class DialogModal{
    controller: DesignerController;
    div: HTMLDialogElement;

    id: string;
    headline: string;
    content: string;
    confirmText: string;
    cancelText: string;

    constructor(id: string){
        this.id = id;
    }

    getHTML(){
        let cancelBtn = "";
        if(this.cancelText){
            cancelBtn = '<button class="designer_dialog_cancel" value="cancel"> '+this.cancelText+'</button>';
        }

        return `<dialog id="${this.id}" class="designer_dialog">
            <div>
                <div>
                    ${this.headline}
                </div>
                ${this.content}
                <form method="dialog">
                    ${cancelBtn}
                    <button class="designer_dialog_confirm" value="default"> ${this.confirmText} </button>
                </form>
            </div>
        </dialog>`;
    }

    show(passalong:any = true): Promise<any>{
        let _this = this;
        return new Promise( (resolve, reject) => {

            if(!_this.div){
                console.error("No DIV set on dialog!");
                reject(passalong);
            }

            let confirm = _this.div.querySelector('.designer_dialog_confirm');
            let cancel = _this.div.querySelector('.designer_dialog_cancel');

            if(cancel){
                cancel?.addEventListener("click", () => {
                    reject(passalong);
                    _this.div.remove();
                });
            }
            
            if(confirm){
                confirm?.addEventListener("click", (event) => {
                    event.preventDefault();
                    resolve(passalong);
                    _this.div.remove();
                });
            }
            

            _this.div.showModal();
        });
    }
}
    
export { DialogInterface};