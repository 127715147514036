import { DesignerController } from "./DesignerController";
import { DesignerElement, DesignerText, DesignerDate, DesignerImage, DesignerRect } from "./DesignerElements";
import { Border, Box, ElementTypes, Metadata, SaveDataDate, SaveDataElement, SaveDataImage, SaveDataRect, SaveDataText, SaveDataTypes, SaveObject } from "./TypescriptInterfaces";

//Offset for dragging elements
class SelectedDragOffset{
    x = 0;
    y = 0;
}

//New savedata class (new designer format)
class SaveData{
    elements: DesignerElement[];
    border: Border|null;
    metadata: Metadata;
    pad: string;

    constructor(designer: DesignerController){
        this.elements = designer.elements;
        this.border = designer.border;
        this.pad = designer.inkpadColor;

        let bounds = designer.calcBounds(designer.canvas, false, true);
        let area = designer.editAreaSize();

        this.metadata = {
            formatVersion: designer.formatVersion,
            offset: {
                x: Utility.roundDigit( (bounds.minX - area.minX) / designer.config.pixelToMM ),
                y: Utility.roundDigit( (bounds.minY - area.minY) / designer.config.pixelToMM )
            },
            dimension: {
                w: Utility.roundDigit( bounds.w / designer.config.pixelToMM),
                h: Utility.roundDigit( bounds.h / designer.config.pixelToMM)
            }
        }
    }

    //Get relevant data form every element and return formated string of save object
    stringify(){
        let saveObject: SaveObject;
        let saveElements: (SaveDataText | SaveDataDate | SaveDataRect | SaveDataImage)[] = [];
        for(let element of this.elements){
            let data!: SaveDataTypes;
            if(element instanceof DesignerText){
                data = element.saveData() as SaveDataText;
            }
            if(element instanceof DesignerDate){
                data = element.saveData() as SaveDataDate;
            }
            if(element instanceof DesignerRect){
                data = element.saveData() as SaveDataRect;
            }
            if(element instanceof DesignerImage){
                data = element.saveData() as SaveDataImage;
            }
            saveElements.push(data);
        }

        saveObject = {
            elements: saveElements,
            border: this.border,
            metadata: this.metadata,
            pad: this.pad
        }
        let str: string = JSON.stringify(saveObject);
        return str;
    }
}

//Data that's required when loading the designer
class DesignData{
    pid = "";
    canvasid = "";
    canvas = "";
    tid = "";
    kdnr = "";
    knr = "";
    mode = "loadcanvas";
    typ = "Stempel";
    pad = "";
    print = null;
    dimension = {
        width: 0,
        height: 0
    }
    products = {
        data: {}
    }
    imageData: string;
    $pixel2mm: number;
    constructor(pid:string, canvasid: string, canvas: string, tid: string, kdnr: string, knr: string, mode:string, typ: string, pad:string, width: number, height: number, products: {}){
        this.pid = pid;
        this.canvasid = canvasid;
        this.canvas = canvas;
        this.tid = tid;
        this.kdnr = kdnr;
        this.knr = knr;
        this.mode = mode;
        this.typ = typ;
        this.pad = pad;
        this.dimension.width = width;
        this.dimension.height = height;
        this.products.data = products;
    }
}

class Utility{
    controller: DesignerController;
    constructor(controller: DesignerController){
        this.controller = controller;
    }

    //Clamp number between min and max
    static clamp(num: number, min: number, max: number) {
        return num <= min ? min : num >= max ? max : num;
    }

    //Get absolute position on page
    static absolutePosition(element: HTMLElement) {
        const rect = element.getBoundingClientRect();
        return {
        x: rect.left + window.scrollX,
        y: rect.top + window.scrollY
        };
    }


    //Get position relative to parent
    static relativePosition(elementChild: HTMLElement, elemenentParent: HTMLElement){
        let rectChild = elementChild.getBoundingClientRect();
        let rectParent = elemenentParent.getBoundingClientRect();

        let deltaX = rectChild.left - rectParent.left;
        let deltaY = rectChild.top - rectParent.top;

        return {
            x: deltaX,
            y: deltaY
        };
    }

    //Convert pt to pixels
    static pt2px(size: number, pixelToMM: number){
        size *= 0.352778;
        size *= pixelToMM;
        return size;
    }

    //Convert pt to mm
    static pt2mm(size:number){
        //1pt = 0.353mm
        return size / 0.353;
    }

    ///Remove hashtag from color code (1. char)
    static removeHashtag(string: string){
        return string.slice(1);
    }

    //Check if point is inside element bounds
    static pointInElement(element: DesignerElement, x: number, y: number){
        //Minimum bounds for easier selection
        let eleX = element.x;
        let eleY = element.y;
        let eleW = element.w;
        let eleH = element.h;

        if(eleX <= x && eleY <= y && eleX+eleW >= x &&eleY+eleH >= y){
            return true;
        }
    }

    //Check if point is inside box bounds
    static pointInBox(box:Box, x: number, y: number){
        if(box.x <= x && box.y <= y && box.x+box.w >= x &&box.y+box.h >= y){
            return true;
        }
    }

    //Split string into multiple lines
    static stringToLines(string:string, maxWidth: number, ctx: CanvasRenderingContext2D): string[]{
        var words = string.split(" ");
        var lines = [];
        var currentLine = words[0];

        for (var i = 1; i < words.length; i++) {
            var word = words[i];
            var width = ctx.measureText(currentLine + " " + word).width;
            if (width < maxWidth) {
                currentLine += " " + word;
            } else {
                lines.push(currentLine);
                currentLine = word;
            }
        }
        lines.push(currentLine);
        return lines;
    }

    //Get string for error message ID
    static getErrorMessage(error: ElementError){
        let messages = {
            0: "Element außerhalb des Gestaltungsbereichs",
            1: "Element zu nahe an Datumsfeld",
            2: "Text überschneidet einen anderen Text",
            3: "Mindestgröße nicht eingehalten",
            4: "Mindestabstand zwischen Farben nicht eingehalten"
        }
        return messages[error];
    }

    //Safe Async image loading
    static loadImage(url: string): Promise<HTMLImageElement>{
        return new Promise((resolve, reject) => {
            let img = new Image();
            img.crossOrigin = 'anonymous';
            img.onload = () => resolve(img);
            img.onerror = () => {
                let src = img.src;
                let filePos = src.indexOf("/files/");
                if(filePos > 0){
                    src = src.replace("/files/", "/muster/");
                    img.src = src;
                }else{
                    reject();
                }
            };

            //prevent cache
            let time = Date.now();
            img.src = url+"?"+time;
        });
    }

    //Round to 3. digit for save data string
    static roundDigit(num: number){
        return Number(num.toFixed(3));
    }
}

enum ElementError{
    OutOutBounds,
    DateCollision,
    TextCollision,
    MCIMinimum,
    MCISpacing
}

export {SelectedDragOffset, SaveData, DesignData, Utility, ElementError}