import { event } from "jquery";
import { API } from "./API";
import { DesignerController } from "./DesignerController";
import { DesignerText, DesignerDate, DesignerImage, DesignerRect, DesignerQRCode } from "./DesignerElements";
import { PopupToggle } from "./DesignerPopup";
import { ActiveTabs, Alignment, Clipart, ColorObject, Config, RenderMode, Tabs, TextSpecials } from "./TypescriptInterfaces";
import { Utility } from "./Utility";
import Choices from "Choices";

//Loading and processing the tabs on the left side
class TabInterface{
    controller: DesignerController;
    config: Config;
    API: API;
    tabsHTML: Tabs|null = null;
    activeTab: ActiveTabs = "text";

    constructor(controller: DesignerController, config:Config, API:API){
        this.controller = controller;
        this.config = config
        this.API = API;
    }

    generateMCIButton(color: string | null): HTMLButtonElement{
        let menuButtonMCI = document.createElement('button');
        menuButtonMCI.classList.add("menu_button", "mci_toggle_button");
        menuButtonMCI.title = "MCI Farbe auswählen";
        let menuIcon = document.createElement('img');
        menuIcon.src = this.controller.config.imagePath+'colors_fill_small.svg';
        if(menuIcon && color){
            menuIcon.style.borderColor = color;
        }
        menuButtonMCI.appendChild(menuIcon);
        return menuButtonMCI;
    }

    //List patterns for this article
    renderPatternTab(){
        if(this.tabsHTML == null){
            return false;
        }
        document.getElementById('config_tabs')!.innerHTML = this.tabsHTML.pattern;
        //Send API request (or load from cache) for text patterns and build and display the list
        this.API.requestTextPatterns(this.config.dataset.artikel).then(
            (data:any) => {
                let text_patterns = document.getElementById('text_patterns');
                for(let key in data){
                    let pattern = data[key]; 
                    let patternDiv = document.createElement('div');
                    let patternDivTitle = document.createElement('div');
                    let patternDivImageBox = document.createElement('div');
                    patternDiv.classList.add("pattern_box");
                    patternDiv.dataset.patternName = pattern.name;
                    patternDiv.dataset.patternID = key;
    
                    let _this = this;
                    patternDiv.addEventListener("click", function(e){
                        let patternID = this.dataset.patternID;
                        if(!patternID){
                            return;
                        }
                        let id = Number.parseInt(patternID);
                        //When there's content, just apply the pattern settings
                        if(_this.controller.elements.length > 0){
                            _this.controller.patternController.loadTextPattern(id, false, false, false);
                        }else{
                            //When there's nothing, load entire pattern
                            _this.controller.patternController.loadTextPattern(id, true, false, true);
                        }
                        
                    });
                    patternDivTitle.innerText = "Muster "+pattern.name;
                    
    
                    patternDivImageBox.classList.add("pattern_box_image");
    
                    let img = document.createElement('div');
                    img.style.backgroundImage = "url("+pattern.jpg+")";
                    
    
                    patternDivImageBox.appendChild(img);
    
                    patternDiv.appendChild(patternDivTitle);
                    patternDiv.appendChild(patternDivImageBox);
                    text_patterns?.appendChild(patternDiv);
                }
            }
        ).catch( () => {
            //Catch error
        })
        let artikel_nr = this.config.dataset.artikel;

        if(this.controller.config.init.designPattern == "false"){
            return;
        }
        //Send API request (or load from cache) for design patterns and build and display the list
        this.API.requestDesignPatterns(artikel_nr).then(
            (data:any) => {
                let design_patterns = document.getElementById('design_patterns');
                for(let key in data){
                    let pattern = data[key]; 
                    let patternDiv = document.createElement('div');
                    let patternDivTitle = document.createElement('div');
                    let patternDivImageBox = document.createElement('div');
                    patternDiv.classList.add("pattern_box");
                    patternDiv.dataset.patternName = pattern.name;
                    patternDiv.dataset.patternID = key;
    
                    let _this = this;
                    patternDiv.addEventListener("click", function(e){
                        let patternID = this.dataset.patternID;
                        if(!patternID){
                            console.error("Pattern has no ID! Can't load.");
                            return;
                        }
                        let id = Number.parseInt(patternID);
                        _this.controller.patternController.loadDesignPattern(id, artikel_nr);
                    });
                    patternDivTitle.innerText = "Muster "+pattern.name;
                    
    
                    patternDivImageBox.classList.add("pattern_box_image");
    
                    let img = document.createElement('div');
                    img.style.backgroundImage = "url("+pattern.jpg+")";
                    
    
                    patternDivImageBox.appendChild(img);
    
                    patternDiv.appendChild(patternDivTitle);
                    patternDiv.appendChild(patternDivImageBox);
                    design_patterns?.appendChild(patternDiv);
                }
            }
        );
    }

    //List users saved patterns
    renderUserPatternTab(){
        if(this.tabsHTML == null){
            return false;
        }
        document.getElementById('config_tabs')!.innerHTML = this.tabsHTML.userpattern;

        if(this.controller.config.userPattern.create == false){
            document.getElementById('userpattern_create')?.remove();
        }

        let codeLoad = document.getElementById('userpattern_code') as HTMLInputElement;
        codeLoad.value = this.config.userCode;

        let codeLoadButton = document.getElementById('userpattern_load_button') as HTMLButtonElement;

        let codeSaveButton = document.getElementById('userpattern_create_button') as HTMLButtonElement;
        let codeSaveName = document.getElementById('userpattern_create_name') as HTMLButtonElement;
        

        this.loadUserPatterns(this.config.dataset.artikel, this.config.userCode);

        //EVENT LISTENER
        let _this = this;
        if(codeLoadButton){
            codeLoadButton.addEventListener('click', function (evt) {
                //Overwrite local code with last used one
                let code = codeLoad.value.trim();
                if(code.length > 0){
                    localStorage.setItem("designer2021_userCode", code);
                    _this.loadUserPatterns(_this.config.dataset.artikel, code, false);
                }
            });
        }

        if(codeSaveButton){
            codeSaveButton.addEventListener('click', function (evt) {
                let name = codeSaveName.value;
                let code = _this.config.userCode;
                let saved = _this.saveUserPattern(code, name);
                if(saved){
                    codeSaveName.value = "";
                }
            });
        }
    }
    
    //List text items for easy editing
    renderTextTab(){
        if(this.activeTab !== "text"){
            return false;
        }
        if(this.tabsHTML == null){
            return false;
        }
        document.getElementById('config_tabs')!.innerHTML = this.tabsHTML.text;
        let listDIV = document.getElementById('item_list')!;
        let i = 1;
        let rowCount = 0;
        for(let el of this.controller.elements){
            if(el instanceof DesignerText){
                if(el.blockedActions.includes("list")){
                    continue;
                }
                rowCount ++;
                let element = el;
                //Build list
                let row = document.createElement('div');
                row.dataset.id = element.id.toString();

                //let wrapGroup1 = document.createElement('div');
    
                let number = document.createElement('span');
                number.innerHTML = i.toString();
    
                let text = document.createElement('input');
                text.classList.add('text_input');
                text.value = element.text;

                let stepSize = this.config.fontIncrement;
                let textsize = this.generateFontSizeDropdown(stepSize, element.size);
                
                
                //Generate text sizes
                
    
                //Generate Font Select from list
                
                let font = this.generateFontDropdown(element.font);
    
                let menuButton = document.createElement('button');
                menuButton.classList.add("menu_button");
                menuButton.title = "Weitere Einstellungen";
                let menuIcon = document.createElement('img');
                menuIcon.src = this.controller.config.imagePath+'tune_black_24dp.svg';
                menuButton.appendChild(menuIcon);

                //MCI Icon
                let menuButtonMCI = this.generateMCIButton(element.color);
    
                //EVENT LISTENERS
                let _this = this;
                text.addEventListener('input', function (evt) {
                    //update Text
                    let textValue = (evt.target as HTMLInputElement).value;
                    element.setText(textValue, false);
                });
    
                row.addEventListener('click', function (evt) {
                    _this.controller.input.elementSelected = element;
                });
    
                menuButton.addEventListener('click', function (evt) {
                    _this.controller.input.elementSelected = element;
                    let pos = Utility.relativePosition(_this.controller.canvas, document.body);
                    _this.controller.openElementToolsOnElement(element);
                    _this.highlightText(element.id);
                });

                menuButtonMCI.addEventListener('click',  (evt) => {
                    this.controller.input.elementSelected = element;
                    let pos = Utility.relativePosition(this.controller.canvas, document.body);
                    this.controller.openElementToolsOnElement(element);

                    let toggle = this.controller.activeElementTools?.findElement("mci_toggle_button") as PopupToggle;
                    toggle.toggleOn();
                    this.highlightText(element.id);
                });
    
                textsize.addEventListener('change', function (evt) {
                    //update Text
                    let size = parseFloat((evt.target as HTMLSelectElement).value);
                    element.setSize(size, true, true, true);
                });
    
                font.addEventListener('change', function (evt) {
                    //update Text
                    let font = (evt.target as HTMLSelectElement).value;
                    element.setFont(font);
                });
    
    
                row.appendChild(number);

                if(!el.blockedActions.includes("changeText") ){
                    row.appendChild(text);
                }
                if(this.config.init.text.changeSize == "true" && !el.blockedActions.includes("scale") ){
                    row.appendChild(textsize);
                }
                if(this.config.init.text.changeFont == "true" && !el.blockedActions.includes("changeFont") ){
                    row.appendChild(font);
                    //Custom dropdown element (<select> isn't stylable in Firefox)
                    const choices = new (Choices as any)(font, {
                        allowHTML: true, 
                        itemSelectText: '',
                        shouldSort: false,
                        searchEnabled: false,
                        callbackOnCreateTemplates: function(template: (arg0: string) => any) {
                            return {
                              item: ({ classNames }: any, data: { highlighted: any; placeholder: any; id: any; value: any; active: any; disabled: any; label: any; }) => {
                                return template(`
                                  <div style="font-family:${data.label}" class="${classNames.item} ${
                                  data.highlighted
                                    ? classNames.highlightedState
                                    : classNames.itemSelectable
                                } ${
                                  data.placeholder ? classNames.placeholder : ''
                                }" data-item data-id="${data.id}" data-value="${data.value}" ${
                                  data.active ? 'aria-selected="true"' : ''
                                } ${data.disabled ? 'aria-disabled="true"' : ''}>
                                    ${data.label}
                                  </div>
                                `);
                              },
                              choice: ({ classNames }: any, data: { disabled: any; id: any; value: any; groupId: number; label: any; }) => {
                                return template(`
                                  <div style="font-family:${data.label}" class="${classNames.item} ${classNames.itemChoice} ${
                                  data.disabled ? classNames.itemDisabled : classNames.itemSelectable
                                }" data-select-text="${this.config.itemSelectText}" data-choice ${
                                  data.disabled
                                    ? 'data-choice-disabled aria-disabled="true"'
                                    : 'data-choice-selectable'
                                } data-id="${data.id}" data-value="${data.value}" ${
                                  data.groupId > 0 ? 'role="treeitem"' : 'role="option"'
                                }>
                                    ${data.label}
                                  </div>
                                `);
                              },
                            };
                        },
                    });

                }

                if(this.controller.MCI ){
                    row.appendChild(menuButtonMCI);
                }

                row.appendChild(menuButton);


                

                //row.appendChild(wrapGroup0);
                //row.appendChild(wrapGroup1);

                listDIV.appendChild(row);
                i++;
            }
        }
        //Utility buttons
        var addTextButton = document.getElementById('add_text_button')!;
        var removeTextButton = document.getElementById('remove_text_button')!;
    
        if(this.config.init.text.addRows == "false"){
            addTextButton.style.display = "none";
        }

        if(this.config.init.text.removeRows == "false"){
            removeTextButton.style.display = "none";
        }
    
        let _this = this;
        addTextButton.addEventListener('click', function (evt) {
            /*
            if(_this.controller.textMaximum()){
                window.alert("Bereits maximale Zeilenanzahl für den Stempel erreicht: "+_this.config.init.text.maxRows)
                return;
            }
            */

            //get bottom X and Y
            let center = _this.controller.editAreaCenter();
            let x = center.x;
            let y = center.y;
            let h = 0;
            let lastText = _this.controller.getLastText();

            if(lastText){
                y = lastText.y;
                x = lastText.x;
                h = lastText.h;
            }

            //Copy last text elements settings if possible
            let size = 11;
            let font = "Arial";
            let align:Alignment = null;

            let lastW = 0;
            if(lastText){
                size = lastText.size;
                font = lastText.font;
                align = lastText.align;
                lastW = lastText.getDimensions().w;
            }

            let area = _this.controller.editAreaSize();

            let yNew = y+(h+_this.config.pixelToMM);

            //Limit to bottom of area
            yNew = Math.min(area.maxY-h, yNew);

            let text = new DesignerText(_this.controller, x, yNew, "Text eingeben", size, font, align);

            if(lastText){
                text.setBold(lastText.bold);
                text.setItalic(lastText.italic);
                text.setUnderline(lastText.underline);
                text.setAlignment(lastText.align);
            }

            let w = text.getDimensions().w;
            //Center under last text
            if(lastText && (text.align == "centerH" || text.align == null)){
                text.x += (lastW - w)/2;
            }

            text.addElement(true,true,true,true);
            text.moveRelative(0, 0, false, false, false);
            _this.controller.input.elementSelected = text;
        });
    
        removeTextButton.addEventListener('click', function (evt) {
            if(_this.controller.textMinimum()){
                window.alert("Bereits minimale Zeilenanzahl für den Stempel erreicht: "+_this.config.init.text.minRows)
                return;
            }
            let last;
            for(let element of _this.controller.elements){
                if(element instanceof DesignerText && !(element instanceof DesignerDate)){
                    last = element;
                }
            }
            if(last){
                _this.controller.deleteElement(last);
            }
            
        });

        this.addApplyToAllListeners();

        this.controller.updateApplyToAllDropdown();
    }
    
    //Tab for clipart and uploads
    renderImageTab(){
        if(this.tabsHTML == null){
            return false;
        }
        document.getElementById('config_tabs')!.innerHTML = this.tabsHTML.image;
        this.controller.setUIValues();
        this.loadClipartCategories();
    
        let _this = this;
        let clipart_category = document.getElementById('clipart_category') as HTMLInputElement;
        clipart_category.addEventListener('change', () => {
            _this.loadClipart( clipart_category.value );
        });
    
        this.loadClipart();
    
        let imageInput = document.getElementById('image_upload') as HTMLInputElement;
        imageInput?.addEventListener('change', function (evt) {
            let selectedFiles = imageInput.files;
            if(selectedFiles && selectedFiles.length > 0){
                _this.controller.uploadImage(selectedFiles[0]);
            }
        });
    }

    //Tab for clipart and uploads
    renderFullTab(){
        if(this.tabsHTML == null){
            return false;
        }
        let tab = document.getElementById('config_tabs');
        tab!.innerHTML = this.tabsHTML.full;
        this.controller.setUIValues();

        let text_placeholder = tab?.querySelector('.text_placeholder') as HTMLDivElement;
        text_placeholder.innerHTML = this.tabsHTML.full_text;

        if(this.controller.MCI){
            text_placeholder.innerHTML = this.tabsHTML.full_text_mci;
        }

        let uploadFullWidthInput = document.getElementById('upload_full_width') as HTMLInputElement;
        uploadFullWidthInput.max = (this.controller.config.maxWidthMM).toString();
        if(this.controller.imageFullWidth){
            uploadFullWidthInput.value = this.controller.imageFullWidth.toString();
        }else{
            uploadFullWidthInput.value = (this.controller.config.maxWidthMM).toString();
        }

        let _this = this;
        uploadFullWidthInput.addEventListener('change', () => {
            this.controller.imageFullWidth = uploadFullWidthInput.valueAsNumber;
        });
    
        
        let upload_full_input = document.getElementById('upload_full') as HTMLInputElement;
        upload_full_input.addEventListener('change', () => {
            let selectedFiles = upload_full_input.files;
            if(selectedFiles && selectedFiles.length > 0){
                let width = uploadFullWidthInput.valueAsNumber;
                if(width){
                    _this.controller.uploadImageFull( selectedFiles[0], width);
                }
                
            }
        });

        upload_full_input.addEventListener('click', function(){
            this.value = '';
        });
    
        let upload_full_label = document.getElementById('upload_full_label') as HTMLLabelElement;

        let addDragClass = function() {
            upload_full_label.classList.add('is-dragover');
        }

        let removeDragClass = function() {
            upload_full_label.classList.remove('is-dragover');
        }

        //Visual Drag & Drop indication
        upload_full_label.addEventListener('dragenter', addDragClass);
        upload_full_label.addEventListener('dragover', addDragClass);

        upload_full_label.addEventListener('dragend', removeDragClass);
        upload_full_label.addEventListener('drop', removeDragClass);
        upload_full_label.addEventListener('dragexit', removeDragClass);
        upload_full_label.addEventListener('dragleave', removeDragClass);
    }
    
    //Show border tab
    renderBorderTab(){
        if(this.tabsHTML == null){
            return false;
        }
        let config_tabs = document.getElementById('config_tabs');
        config_tabs!.innerHTML = this.tabsHTML.border;
        this.controller.setUIValues();
    
        let _this = this;
        let border_strength = document.getElementById('border_strength') as HTMLInputElement;
        let border_padding = document.getElementById('border_padding') as HTMLInputElement;
    
        border_strength.addEventListener('change', () => {
            _this.setBorderWidth( border_strength.valueAsNumber );
            _this.controller.preScaleBorderWidth = this.controller.border.width / this.controller.scaling;
        });
    
        border_padding.addEventListener('change', () => {
            _this.setBorderPadding( border_padding.valueAsNumber );
            _this.controller.preScaleBorderPadding = this.controller.border.padding / this.controller.scaling;
        });
        
        document.getElementById('border_strength_add')?.addEventListener("click", () => {
            border_strength.value = (border_strength.valueAsNumber + 0.25).toFixed(2);
            _this.setBorderWidth( border_strength.valueAsNumber );
            _this.controller.preScaleBorderWidth = this.controller.border.width / this.controller.scaling;
        });
        document.getElementById('border_strength_remove')?.addEventListener("click", () => {
            border_strength.value = Math.max(0.25, border_strength.valueAsNumber - 0.25).toFixed(2);
            _this.setBorderWidth( border_strength.valueAsNumber );
            _this.controller.preScaleBorderWidth = this.controller.border.width / this.controller.scaling;
        });
    
        document.getElementById('border_padding_add')?.addEventListener("click", () => {
            border_padding.value = (border_padding.valueAsNumber + 0.25).toFixed(2);
            _this.setBorderPadding( border_padding.valueAsNumber );
            _this.controller.preScaleBorderPadding = this.controller.border.padding / this.controller.scaling;
        });
        document.getElementById('border_padding_remove')?.addEventListener("click", () => {
            border_padding.value = Math.max(0, border_padding.valueAsNumber - 0.25).toFixed(2);
            _this.setBorderPadding( border_padding.valueAsNumber );
            _this.controller.preScaleBorderPadding = this.controller.border.padding / this.controller.scaling;
        });
    
        let borderTypeButtons = document.getElementsByClassName("border_type");
        for(let button of borderTypeButtons){
            //Show active option
            let val = (button as HTMLElement).dataset.value;
            if(val == "single" && this.controller.border.active && this.controller.border.double == false){
                button.classList.add("list_button_active");
            }
            if(val == "double" && this.controller.border.active && this.controller.border.double == true){
                button.classList.add("list_button_active");
            }
            if(val == "none" && this.controller.border.active == false){
                button.classList.add("list_button_active");
            }
    
            button.addEventListener("click", () => {
                for(let button2 of borderTypeButtons){
                    button2.classList.remove("list_button_active");
                }
                if(val == "single"){
                    this.controller.border.active = true;
                    this.controller.border.double = false; 
                }
                if(val == "double"){
                    this.controller.border.active = true;
                    this.controller.border.double = true; 
                }
                if(val == "none"){
                    this.controller.border.active = false;
                }
                button.classList.add("list_button_active");
                this.controller.update();
            });
        }

        //LINES
        let line_width = document.getElementById('line_width') as HTMLInputElement;
        let line_length = document.getElementById('line_length') as HTMLInputElement;

        //LINE WIDTH
        line_width.addEventListener('change', () => {
            _this.setLineWidth( line_width.valueAsNumber );
        });

        document.getElementById('line_width_add')?.addEventListener("click", () => {
            line_width.value = Math.min(10, line_width.valueAsNumber + 0.25 ).toFixed(2);
            _this.setLineWidth( line_width.valueAsNumber );
        });
        document.getElementById('line_width_remove')?.addEventListener("click", () => {
            line_width.value = Math.max(0.25, line_width.valueAsNumber - 0.25).toFixed(2);
            _this.setLineWidth( line_width.valueAsNumber );
        });


        //LINE LENGTH
        line_length.addEventListener('change', () => {
            _this.setLineLength( line_length.valueAsNumber );
        });

        document.getElementById('line_length_add')?.addEventListener("click", () => {
            line_length.value = Math.min(100, line_length.valueAsNumber + 0.25).toFixed(2);
            _this.setLineLength( line_length.valueAsNumber );
        });
        document.getElementById('line_length_remove')?.addEventListener("click", () => {
            line_length.value = Math.max(1, line_length.valueAsNumber - 0.25).toFixed(2);
            _this.setLineLength( line_length.valueAsNumber );
        });

        //LINE ORIENTATION
        let orientationButtons = document.getElementsByClassName("line_orientation");
        for(let button of orientationButtons){
            //Show active option
            let val = (button as HTMLElement).dataset.value;
            if(val == "horizontal" && this.controller.lineSpawn.orientation == "horizontal"){
                button.classList.add("list_button_active");
            }
            if(val == "vertical" &&  this.controller.lineSpawn.orientation == "vertical"){
                button.classList.add("list_button_active");
            }
    
            button.addEventListener("click", () => {
                //Clear classes on value change
                for(let button2 of orientationButtons){
                    button2.classList.remove("list_button_active");
                }
                if(val == "horizontal"){
                    this.controller.lineSpawn.orientation = "horizontal";
                }
                if(val == "vertical"){
                    this.controller.lineSpawn.orientation = "vertical";
                }
                button.classList.add("list_button_active");
                this.controller.update();
            });
        }

        //LINE SPAWN
        document.getElementById('add_line_button')?.addEventListener("click", () => {
            let center = _this.controller.editAreaCenter();
            let x = center.x;
            let y = center.y;

            let w = _this.controller.lineSpawn.length * _this.controller.config.pixelToMM;
            let h = _this.controller.lineSpawn.width * _this.controller.config.pixelToMM;
            let orientation = _this.controller.lineSpawn.orientation;
            
            let rect = new DesignerRect(_this.controller, x, y, w, h, _this.controller.inkpadColor, orientation);
            rect.addElement(true);

            _this.controller.input.elementSelected = rect;
        });

        if(this.controller.MCI){
            let div = document.getElementById('border_color_select') as HTMLDivElement;
            if(div){
                this.generateBorderColors(div);
            }
        }else{
            document.getElementById('border_color_group')?.remove();
        }
    }

    //Show QR tab
    renderQRTab(){
        if(this.tabsHTML == null){
            return false;
        }
        let tab = document.getElementById('config_tabs');
        tab!.innerHTML = this.tabsHTML.qr;
        this.controller.setUIValues();

        let tidInput = document.getElementById('qr_vk_tid') as HTMLInputElement;
        if(tidInput){
            tidInput.value = this.config.dataset.tid;
        }

        let copyButtonHTML = '<button id="copy_vk_edit_link_button" class="copy_button" title="In Zwischenablage kopieren"> <img src="'+this.config.imagePath+'copy_white_24dp.svg" class="icon invert"/> </button>';

        //Submit
        let form = document.getElementById('qr_vk_form') as HTMLFormElement;
        form.addEventListener('submit', (event) => {
            let target = event.target as HTMLFormElement;

            this.API.GenerateVK( new FormData(target) ).then(
                (data:any) => {
                    console.log(data);
                    if(data){
                        if(data.savedResult){
                            this.controller.vkdata.url = data.link;
                            this.controller.vkdata.urlEdit = data.edit;

                            this.controller.setUIValues();

                            if(data.qrcode){
                                //Create QR Code
                                let exist = this.controller.checkQRCodeExists(data.link);
                                if(exist == null){
                                    let size = 30 * this.config.pixelToMM;
                                    let area = this.controller.editAreaSize();
                                    let pad = this.config.pixelToMM * 2;

                                    size = Math.min(area.w - pad, area.h - pad, size);
                                    size = Math.max(size, this.config.QRMinHeight * this.config.pixelToMM, this.config.QRMinWidth * this.config.pixelToMM);

                                    let img = new DesignerQRCode(this.controller, 0, 0, data.qrcode, true, "#000000", size, size);
                                    img.text = data.link;
                                    img.center();
                                    img.addElement();
                                }else{
                                    exist.setImage(data.qrcode, true);
                                }
                            }

                            if(data.updated == false){
                                
                                this.controller.showMessage("success", `Digitale Visitenkarte erfolgreich erstellt: 
                                    <br>
                                    <br> 
                                    <div> 
                                        - VK-Code: `+data.token + `<br> 
                                        - Link zur Visitenkarte: <a href="`+data.link+`" target='_blank'> `+data.link+` </a> <br>
                                        - Link zum späteren Bearbeiten: `+copyButtonHTML+` <a href="`+data.edit+`" target='_blank'>  `+data.edit+` </a> 
                                    </div>`, false);

                                let copyButton = document.getElementById('copy_vk_edit_link_button');
                                if(copyButton){
                                    copyButton.addEventListener('click', () => {
                                        navigator.clipboard.writeText(data.edit);
                                    });
                                }
                            }else{
                                this.controller.showMessage("success", `Digitale Visitenkarte aktualisiert:
                                    <br>
                                    <br> 
                                    <div> 
                                        - VK-Code: `+data.token + `<br> 
                                        - Link zur Visitenkarte: <a href="`+data.link+`" target='_blank'> `+data.link+` </a> <br>
                                        - Link zum späteren Bearbeiten: `+copyButtonHTML+` <a href="`+data.edit+`" target='_blank'>  `+data.edit+` </a>
                                    </div>`, false);
                            }
                        }
                        if(!data.savedResult){
                            this.controller.showMessage("error", "Fehler beim Erstellen der Visitenkarte: VK-Code:"+data.token);
                        }
                    }
                }
            );

            event.preventDefault();
        });

        let vk_image_input = document.getElementById('vk_image') as HTMLInputElement;
        vk_image_input?.addEventListener('change', (evt) => {
            let selectedFiles = vk_image_input.files;
            if(selectedFiles && selectedFiles.length > 0){
                this.controller.uploadImageVK(selectedFiles[0]);
            }
        });

        //Save input values
        let vkElements = form.elements;
        for(let element of vkElements){
            let input = element as HTMLInputElement;
            input.addEventListener('change', (evt) => {
                let el = evt.target as HTMLInputElement;
                this.controller.vkdata[el.name] = el.value;
                console.log(this.controller.vkdata);
            });
        }

        //Copy
        let vk_link_edit_copy_static = document.getElementById('copy_vk_edit_link_button_static') as HTMLAnchorElement;
        if(vk_link_edit_copy_static){
            vk_link_edit_copy_static.addEventListener('click', () => {
                if(this.controller.vkdata.urlEdit !== null){
                    navigator.clipboard.writeText(this.controller.vkdata.urlEdit);
                }
            });
        }
    }

    generateBorderColors(div: HTMLDivElement){
        for(let key in this.controller.MCIColors){
            let color:ColorObject = this.controller.MCIColors[key];

            let colorButton = document.createElement('div');
            colorButton.classList.add('mci_color_button', 'border_color_button');
            colorButton.style.backgroundColor = color.hex;
            colorButton.dataset.color = color.hex;
            colorButton.dataset.colorName = color.name;

            colorButton.addEventListener('click', (evt) => {
                if(!evt.target){
                    return;
                }
                let target = (evt.target as HTMLDivElement);
                let buttonColor = target.dataset.color;
                if(buttonColor){
                    this.setBorderColor(buttonColor);

                    //Set active buttons and highlight
                    let otherButtons = this.controller.designerDiv.querySelectorAll('.mci_color_button.border_color_button');
                    for(let otherButton of otherButtons){
                        otherButton.classList.remove('active');
                    }
                    colorButton.classList.add('active');
                    this.controller.update();
                }
            });

            if(this.controller.border.color == color.hex){
                colorButton.classList.add('active');
            }

            div.appendChild(colorButton);
        }
    }

    setBorderColor(color:string){
        this.controller.border.color = color;
        this.controller.update();
    }

    //Set width of newly spawned lines
    setLineWidth(value: number){
        this.controller.lineSpawn.width = value;
        this.controller.update();
    }

    //Set length of newly spawned lines
    setLineLength(value: number){
        this.controller.lineSpawn.length = value;
        this.controller.update();
    }

    //Load clipart categories and create dropdown
    loadClipartCategories(){
        this.API.requestClipart().then(
            (data:any) => {
                let clipart_category = document.getElementById('clipart_category');
                for(let key in data){
                    //Category List
                    if(key == "kategorien"){
                        let categories: string[] = data[key]; 
                        for(let category of categories){
                            let option = document.createElement("option");
                            option.value = category;
                            option.innerHTML = category;
                            clipart_category?.appendChild(option);
                        }
                        break;
                    }
                }
            }
        );
    }

    //Load Clipart collection
    loadClipart(filterCategory:string|null = null){
        this.API.requestClipart().then(
            (data:any) => {
                let clipart_list = document.getElementById('clipart_list');

                if(!clipart_list){
                    return;
                }

                for(let key in data){
                    //Ignore category list
                    if(key == "kategorien"){
                        continue;
                    }
                    //Continue if category is not the one currently selected
                    if(filterCategory != null && filterCategory != key){
                        continue;
                    }
                    
                    clipart_list.innerHTML = "";
                    //Create clipart elements
                    let category = data[key]; 
                    for(let keyClipart in category){
                        let clipart:Clipart = category[keyClipart];
                        let clipartDiv = document.createElement('div');
                        let url = this.config.clipartPath+clipart.path;
                        clipartDiv.style.backgroundImage = 'url('+url+')';
                        clipartDiv.classList.add("clipart");
                        clipartDiv.dataset.url = url;
                        clipart_list.appendChild(clipartDiv);

                        let _this = this;
                        clipartDiv.addEventListener("click", function(){
                            

                            let w = Number.parseFloat(clipart.width);
                            let h = Number.parseFloat(clipart.height);

                            w /= _this.config.clipartScaling;
                            h /= _this.config.clipartScaling;

                            w = Math.max(_this.controller.config.imageSpawnMinWidth * _this.controller.config.pixelToMM, w);
                            h = Math.max(_this.controller.config.imageSpawnMinHeight * _this.controller.config.pixelToMM, h);

                            let area = _this.controller.editAreaSize();
                            //Get scale of what can fit
                            let scaleW = area.w / w;
                            let scaleH = area.h / h;
                            let scale = Math.min(scaleW, scaleH);

                            //Scale down to fit
                            if(scale < 1){
                                w *= scale;
                                h *= scale;
                            }
                            

                            let center = _this.controller.editAreaCenter();

                            

                            let image = new DesignerImage(_this.controller, center.x, center.y, url, true, "#000000", w, h);
                            //image.clampSize();
                            image.center();
                            image.addElement();
                        });
                    }
                    
                    //If no category selected, stop at first
                    if(filterCategory == null){
                        break;
                    }
                }
            }
        );
    }

    //Display user patterns in a grid list
    loadUserPatterns(artikel_nr: number | string, userCode: string, useCache = true){
        this.controller.API.requestUserPatterns(artikel_nr, userCode, useCache).then(
            (data:any) => {
                let user_patterns = document.getElementById('userpattern_patterns_list');
                if(!user_patterns){
                    return;
                }

                //Clear list
                user_patterns.innerHTML = "";

                //Build user pattern list
                for(let key in data){
                    let pattern = data[key]; 
                    let patternDiv = document.createElement('div');
                    let patternDivTitle = document.createElement('div');
                    patternDivTitle.classList.add("pattern_user_title");
                    let patternDivImageBox = document.createElement('div');
                    patternDiv.classList.add("pattern_box");
                    patternDiv.dataset.patternName = pattern.name;
                    patternDiv.dataset.patternID = key;
                    patternDiv.dataset.ident = pattern.ident;
    
                    let _this = this;
                    patternDiv.addEventListener("click", function(e){
                        let patternID = this.dataset.patternID;
                        if(!patternID){
                            console.error("Pattern has no ID! Can't load.");
                            return;
                        }
                        let id = Number.parseInt(patternID);
                        _this.controller.patternController.loadUserPattern(artikel_nr, userCode, id);
                    });

                    let patternDivName = document.createElement('div');
                    patternDivName.innerText = "Muster "+pattern.name;

                    //Delete element
                    let patternDivDelete = document.createElement('div');
                    patternDivDelete.innerText = "X";
                    patternDivDelete.title = "Delete";
                    patternDivDelete.classList.add("pattern_user_title_delete");

                    patternDivDelete.addEventListener("click", function(e){
                        e.stopPropagation();
                        let pattern_box = this.parentElement?.parentElement;
                        if(!pattern_box){
                            return;
                        }

                        let dialog = _this.controller.dialogInterface.addDialog("Muster " + pattern_box.dataset.patternName + " wirklich löschen?", "", "Ja", "Nein");

                        dialog.show(pattern_box).then( (pattern_box) => {
                            let PatternIdent = pattern_box.dataset.ident;
                            if(!PatternIdent){
                                console.error("Pattern has no ident! Can't delete.");
                                return;
                            }
                            let ident = Number.parseInt(PatternIdent);
                            _this.controller.patternController.deleteUserPattern(artikel_nr, userCode, ident);
                            pattern_box.remove();
                        });
                    });

                    patternDivTitle.appendChild(patternDivName);

                    if(this.controller.config.userPattern.delete == true){
                        patternDivTitle.appendChild(patternDivDelete);
                    }
    
                    patternDivImageBox.classList.add("pattern_box_image");
    
                    let img = document.createElement('div');
                    img.style.backgroundImage = "url("+pattern.jpg+")";
                    
    
                    patternDivImageBox.appendChild(img);
    
                    patternDiv.appendChild(patternDivTitle);
                    patternDiv.appendChild(patternDivImageBox);
                    user_patterns.appendChild(patternDiv);
                }
            }
        )
    }

    //Generate neccesary data and save via API
    saveUserPattern(code: string, name: string): boolean{
        let designData = this.controller.generateDesignData();
        if(!designData){
            console.error('Couldnt generate design data');
            return false;
        }

        let imageData = this.controller.getImageData(RenderMode.Preview);
        if(!imageData){
            console.error('Couldnt generate image data');
            return false;
        }

        this.controller.API.uploadUserPattern(this.config.dataset.artikel, code , this.config.dataset.tid, name, designData, imageData, this.config.pixelToMM, this.controller.MCI).then(
            (data:any) => {
                if(!data){
                    console.error("Empty Server Response");
                    return false;
                }
                if(data.data){
                    let message = data.message;

                    this.controller.showMessage("success", message);

                    //Update pattern list
                    this.loadUserPatterns(this.config.dataset.artikel, code, false);

                    return true;
                }else{
                    console.log("Couldn't save pattern");
                    return false;
                }
            }
        ,(error) => {
            console.error(error);
            return false;
        });
        return false;
    }

    //Set border width and update canvas
    setBorderWidth(value: number){
        let val = Utility.clamp(value, this.config.borderMinWidth, this.config.borderMaxWidth);
        val = Utility.roundDigit(val);
        this.controller.border.width = val;
        this.controller.update();
    }

    //Set border padding and update canvas
    setBorderPadding(value: number){
        this.controller.border.padding = value;
        this.controller.update();
    }

    //Mark active tab with special background
    showActiveTab(){
        let icons = document.getElementsByClassName("toolbar_icon");
        for(let icon of icons){
            icon.classList.remove("active");
        }
        switch(this.activeTab){
            case 'text':
                this.renderTextTab();
                document.getElementById("toolbar_text")?.classList.add("active");
            break;
            case 'pattern': 
                this.renderPatternTab();
                document.getElementById("toolbar_pattern")?.classList.add("active");
            break;
            case 'userpattern': 
                this.renderUserPatternTab();
                document.getElementById("toolbar_userpattern")?.classList.add("active");
            break;
            case 'image': 
                this.renderImageTab();
                document.getElementById("toolbar_image")?.classList.add("active");
            break;
            case 'border':
                this.renderBorderTab();
                document.getElementById("toolbar_border")?.classList.add("active");
            break;
            case 'full':
                this.renderFullTab();
                document.getElementById("toolbar_full")?.classList.add("active");
            break;
            case 'qr':
                this.renderQRTab();
                document.getElementById("toolbar_qr")?.classList.add("active");
            break;
        }
    }

    //Highlight clicked text element in the text list
    highlightText(id: number){
        if(this.activeTab != "text"){
            return;
        }
        let list = document.getElementById("item_list");
        let children = list?.childNodes as NodeListOf<HTMLDivElement>;
        if(!children){
            return;
        }
        this.unhighlightText();
        for(let child of children){
            if(child.dataset.id == id.toString() ){
                child.classList.add("designer_highlight");
                /*
                let input = child.getElementsByClassName("text_input")[0] as HTMLInputElement;
                if(input){
                    //Fix for focus not working
                    window.setTimeout(function () { 
                        input.focus();
                    }, 0); 
                    
                }*/
            }
        }
    }

    //Remove highlighting from every row in the text list
    unhighlightText(){
        if(this.activeTab != "text"){
            return;
        }
        let list = document.getElementById("item_list");
        let children = list?.childNodes as NodeListOf<HTMLDivElement>;
        if(!children){
            return;
        }
        for(let child of children){
            child.classList.remove("designer_highlight");
        }
    }

    addApplyToAllListeners(){
        document.getElementById('apply_to_all')?.addEventListener('change', (evt) => {
            this.controller.updateApplyToAllDropdown();
        });
        
        
        document.getElementById('apply_to_all_button')?.addEventListener('click', (evt) => {
            let select = document.getElementById('apply_to_all') as HTMLSelectElement;
            if(!select){
                return;
            }
            let selectedOption = select.value;
            let input;
            switch(selectedOption){
                case 'fontsize':
                    input = document.querySelector(".textsize_select.designer_apply_to_all_input") as HTMLSelectElement;
                    if(input){
                        let value = Number.parseFloat(input.value);
                        for(let element of this.controller.elements){
                            if(! (element instanceof DesignerText) ){
                                continue;
                            }
                            element.setSize(value, true, true, true, false);
                        }
                        for(let element of this.controller.elements){
                            if(! (element instanceof DesignerText) ){
                                continue;
                            }
                            element.moveRelative(0,0);
                        }
                        this.controller.update(true, true, true);
                    }
                break;
                case 'font':
                    input = document.querySelector(".font_select.designer_apply_to_all_input") as HTMLSelectElement;
                    if(input){
                        let value = input.value;
                        for(let element of this.controller.elements){
                            if(! (element instanceof DesignerText) ){
                                continue;
                            }
                            element.setFont(value, true, true, false);
                        }
                        this.controller.update(true, true, true);
                    }
                break;
                case 'align':
                    input = document.querySelector(".align_select.designer_apply_to_all_input") as HTMLSelectElement;
                    if(input){
                        let value = input.value as Alignment;
                        if(input.value == "none"){
                            value = null;
                        }
                        
                        for(let element of this.controller.elements){
                            if(! (element instanceof DesignerText) ){
                                continue;
                            }
                            element.setAlignment(value, false);
                            element.applyAlignment(true);
                        }

                        //Second pass for changed bounds
                        for(let element of this.controller.elements){
                            if(! (element instanceof DesignerText) ){
                                continue;
                            }
                            element.applyAlignment(true);
                        }
                        this.controller.update(true, true, true);
                    }
                break;
                case 'text_special':
                    input = document.querySelector(".text_special_select.designer_apply_to_all_input") as HTMLSelectElement;
                    if(input){
                        let value = input.value;
                        for(let element of this.controller.elements){
                            if(! (element instanceof DesignerText) ){
                                continue;
                            }
                            if(value == "none"){
                                element.setBold(false);
                                element.setItalic(false);
                                element.setUnderline(false);
                                element.setDimensions(true);
                            }
                            if(value == "bold"){
                                element.setBold(true);
                                element.setDimensions(true);
                            }
                            if(value == "italic"){
                                element.setItalic(true);
                                element.setDimensions(true);
                            }
                            if(value == "underline"){
                                element.setUnderline(true, false);
                                element.setDimensions(true);
                            }
                        }
                        this.controller.update(true, true, true);
                    }
                break;
            }
        });
    }

    generateFontSizeDropdown(stepSize: number, activeSize: number = 0){
        let textsize = document.createElement("select");
        textsize.classList.add('textsize_select');
        for(let size:number = this.config.fontMinSize;size <= this.config.fontMaxSize;size += stepSize){
            let sizeSteps = 1/stepSize;
            let sizeRounded = Math.round(size * sizeSteps)/sizeSteps;

            let elementSizeRounded = Math.round(activeSize * sizeSteps)/sizeSteps;

            var option = document.createElement("option");
            let sizeStr = sizeRounded.toString();
            option.value = sizeStr;
            if(sizeStr.includes(".")){
                option.text = Math.floor(sizeRounded).toString()+"½pt";
            }else{
                option.text = sizeStr+"pt";
            }
            option.text = sizeStr+"pt";
            
            if(elementSizeRounded == sizeRounded){
                option.selected = true;
            }
            textsize.appendChild(option);
            if(size >= 20){
                stepSize = 1;
            }
        }

        return textsize;
    }

    generateFontDropdown(activeFont: string = ""){
        let font = document.createElement("select");
        font.classList.add('font_select');

        for(let fontname of this.controller.fonts){
            var option = document.createElement("option");
            option.value = fontname;
            option.text = fontname;
            option.style.fontFamily = fontname;
            if(activeFont == fontname){
                option.selected = true;
            }
            font.appendChild(option);
        }
        return font;
    }

    generateAlignDropdown(activeAlignment: Alignment){
        let align = document.createElement("select");
        align.classList.add('align_select');

        let alignments:Array<Alignment|"none"> = new Array("centerH", "left", "right", "none");
        let alignmentsName:string[] = new Array("Mittig", "Links", "Rechts", "Keine");

        for(let index in alignments){
            let alignment = alignments[index];
            if(!alignment){
                continue;
            }
            var option = document.createElement("option");
            option.value = alignment;
            option.text = alignmentsName[index];
            if(activeAlignment == alignment){
                option.selected = true;
            }
            align.appendChild(option);
        }
        return align;
    }
    generateSpecialDropdown(activeSpecial: TextSpecials){
        let align = document.createElement("select");
        align.classList.add('text_special_select');

        let specialTypes:Array<TextSpecials|"none"> = new Array("bold", "italic", "underline", "none");
        let specialNames:string[] = new Array("Fett", "Kursiv", "Unterstrichen", "Keine");

        for(let index in specialTypes){
            let special = specialTypes[index];
            if(!specialTypes){
                continue;
            }
            var option = document.createElement("option");
            option.value = special;
            option.text = specialNames[index];
            if(activeSpecial == special){
                option.selected = true;
            }
            align.appendChild(option);
        }
        return align;
    }
}

export {TabInterface};