import { DesignerElement, DesignerImage, DesignerText} from "./DesignerElements";
import {DesignerController} from './DesignerController';
import {Debug} from './Debug'

//initiate designer
var designer: DesignerController;
var canvas: HTMLCanvasElement;

var config:any;



function load(data:any){
    config = data;
    designer = new DesignerController(config);
    designer.init().then(() => {
        canvas = designer.canvas;
    });
}

function loadConfig(){
    Debug.DIVSelectMenu();
    let designerDIV = document.getElementById("designer");
    if(designerDIV){
        let config_url = "https://dev.stempelcloud24.com/interfaces/api_konfi_neu/API_DEV/config.json";
        //use URL on DIV if available
        let overwrite_url = designerDIV.dataset.config_url;
        if(overwrite_url){
            config_url = overwrite_url;
        }
        fetch(config_url)
        .then(response => response.json())
        .then(data => load(data));
    }else{
        console.warn("No Desinger DIV element found.")
    }
}

//Document already loaded
if (document.readyState !== 'loading') {
    loadConfig();
}else{
    window.addEventListener('DOMContentLoaded', () => {
        loadConfig();
    });
}

