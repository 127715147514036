import { DesignerController } from "./DesignerController";
import { DesignerDate, DesignerElement, DesignerImage, DesignerRect, DesignerText } from "./DesignerElements";
import { ColorObject } from "./TypescriptInterfaces";

class Popup{
    x: number;
    y: number;
    yOffset = 0;
    xOffset = 0;
    controller: DesignerController;
    element: DesignerElement;
    div: HTMLDivElement;
    menuElements: Array<PopupElement|PopupGroup> = [];
    submenu: PopupSubmenu | null;
    submenuToggle: PopupToggle | null;
    constructor(controller: DesignerController, element: DesignerElement, x: number, y:number, yOffset: number){
        this.controller = controller;
        this.element = element;

        this.div = document.createElement("div");
        this.div.classList.add("designer_popup_menu");
        this.x = x;
        this.y = y;
        this.yOffset = yOffset;
        
        controller.canvasDIV.prepend(this.div);
    }

    addElemenent(element: PopupElement | PopupGroup | PopupSubmenu){
        this.menuElements.push(element)
    }

    /**
     * Finds the first element in the menu with the given class name.
     * @param className The class name to search for.
     * @returns The first `PopupElement` in the menu with the given class name, or `null` if no such element exists.
     */
    findElement(className: string): PopupElement | null{
        for(let el of this.menuElements){
            if(el.div.classList.contains(className)){
                return el;
            }
        }
        return null;
    }


    removeElement(element: PopupElement | PopupGroup | PopupSubmenu){
        let i = this.menuElements.indexOf(element);
        this.menuElements.splice(i, 1);
    }

    getUsableMenuElements(){
        let i = 0;
        for(let el of this.menuElements){
            if( el instanceof PopupGroup){
                for(let groupEl of el.elements){
                    if( !(groupEl instanceof PopupSpacer) ){
                        i ++;
                    }
                }
                continue;
            }

            if( !(el instanceof PopupSpacer) ){
                i ++;
            }
        }
        return i;
    }

    close(){
        this.div.remove();
    }

    setSubmenu(submenu : PopupSubmenu){
        this.submenu = submenu;
        this.addElemenent(submenu);
        this.div.appendChild(submenu.div);
    }

    closeSubmenu(){
        this.submenu?.div.remove();
        this.submenu = null;
        this.submenuToggle?.toggleOff(false);
    }

    move(x: number, y: number) {
        this.x = x;
        this.y = y;
        this.setPosition();
    }

    setPosition(){
        let x = (this.x+this.xOffset);
        let y = (this.y+this.yOffset-this.div.offsetHeight-2);

        //Flip at edges
        /*
        if(y < 0){
            y += (-this.yOffset*2)+this.element.h+this.div.offsetHeight;
        }
        */
        if(x < 0){
            x = 0;
        }

        this.div.style.left = x.toString()+"px";
        this.div.style.top = y.toString()+"px";
    }

    center(){
        this.xOffset = -this.div.offsetWidth/2;
        this.setPosition();
    }
}

class ElementTools extends Popup{
    constructor(DesignerController: DesignerController, element: DesignerElement, x: number, y:number, yOffset: number){
        super(DesignerController, element, x,y, yOffset);

        if(this.element instanceof DesignerText && !(this.element instanceof DesignerDate) ){
            this.loadTextMenu();
        }
        if(this.element instanceof DesignerDate ){
            this.loadDateMenu();
        }

        if(this.element instanceof DesignerImage){
            this.loadImageMenu();
        }

        if(this.element instanceof DesignerRect){
            this.loadRectMenu();
        }

        for(let el of this.menuElements){
            if(el instanceof PopupGroup){
                if(el.elements.length > 0){
                    this.div.appendChild(el.div);
                }
                continue;
            }
            this.div.appendChild(el.div);
        }

        this.center();
    }

    loadTextMenu(){
        //Group 1
        let groupFontSettings = new PopupGroup(this);
        
        let element = this.element as DesignerText;
        let icon, button;

        let fontHasBold = false;
        if(this.controller.fontsBold.includes(element.font) ){
            fontHasBold = true;
        }

        //Bold Toggle
        if(this.controller.config.init.text.bold == "true" && fontHasBold){
            button = new PopupToggle(this, "Fett", "F",element.bold, 
            () => {
                element.setBold(true);
            },
            () => {
                element.setBold(false);
            });
            button.addClass("bold");
            groupFontSettings.add(button);
        }

        let fontHasItalic = false;
        if(this.controller.fontsItalic.includes(element.font) ){
            fontHasItalic = true;
        }

        //Italic toggle
        if(this.controller.config.init.text.italic == "true" && fontHasItalic){
            button = new PopupToggle(this, "Kursiv", "K",element.italic, 
            () => {
                element.setItalic(true);
            },
            () => {
                element.setItalic(false);
            });
            button.addClass("italic");
            groupFontSettings.add(button);
        }

        //Underline toggle
        if(this.controller.config.init.text.underline == "true" && !element.blockedActions.includes("underline")){
            button = new PopupToggle(this, "Unterstrichen", "U",element.underline, 
            () => {
                element.setUnderline(true);
            },
            () => {
                element.setUnderline(false);
            });
            button.addClass("underline");
            groupFontSettings.add(button);
        }

        this.addElemenent(groupFontSettings);

        this.addMCIColorPick();

        //Rotate
        if(!element.blockedActions.includes("move")){
            icon = '<img class="invert" src="'+this.controller.config.imagePath+'refresh_white_24dp.svg">';
            let buttonRotate = new PopupAction(this, "Ausrichtung", icon, 
            () => {
                element.setRotation(element.rotation+45);
            });
        
            this.addElemenent(buttonRotate);
        }

        //Group 2
        if(this.controller.config.init.text.changeAlignment == "true" && !element.blockedActions.includes("changeAlignment") ){

            let groupAlignHorizontal = new PopupGroup(this);

            //Alignment Left
            icon = '<img class="invert popup_padding" src="'+this.controller.config.imagePath+'align_horizontal_left_white_24dp.svg">';
            let buttonAlignLeft = new PopupToggle(this, "Ausrichtung", icon, (element.align == "left"), 
            () => {
                element.setAlignment("left");
                element.moveRelative(0,0);
            },
            () => {
                element.resetAlignment();
            }, "alignment");
            groupAlignHorizontal.add(buttonAlignLeft);

            //Alignment Center
            icon = '<img class="invert popup_padding" src="'+this.controller.config.imagePath+'align_horizontal_center_white_24dp.svg">';
            let buttonAlignCenterH = new PopupToggle(this, "Ausrichtung", icon, (element.align == "centerH"), 
            () => {
                element.setAlignment("centerH");
                element.moveRelative(0,0);
            },
            () => {
                element.resetAlignment();
            }, "alignment");
            groupAlignHorizontal.add(buttonAlignCenterH);

            //Alignment Right
            icon = '<img class="invert popup_padding" src="'+this.controller.config.imagePath+'align_horizontal_right_white_24dp.svg">';
            let buttonAlignRight = new PopupToggle(this, "Ausrichtung", icon, (element.align == "right"), 
            () => {
                element.setAlignment("right");
                element.moveRelative(0,0);
            },
            () => {
                element.resetAlignment();
            }, "alignment");
            groupAlignHorizontal.add(buttonAlignRight);

            //Group 3
            let groupAlignVertical = new PopupGroup(this);

            //Alignment Top
            icon = '<img class="invert popup_padding" src="'+this.controller.config.imagePath+'align_vertical_top_white_24dp.svg">';
            let buttonAlignTop = new PopupToggle(this, "Ausrichtung", icon, (element.align == "top"), 
            () => {
                element.setAlignment("top");
                element.moveRelative(0,0);
            },
            () => {
                element.resetAlignment();
            }, "alignment");
            groupAlignVertical.add(buttonAlignTop);

            //Alignment Center
            icon = '<img class="invert popup_padding" src="'+this.controller.config.imagePath+'align_vertical_center_white_24dp.svg">';
            let buttonAlignCenterV = new PopupToggle(this, "Ausrichtung", icon, (element.align == "centerV"), 
            () => {
                element.setAlignment("centerV");
                element.moveRelative(0,0);
            },
            () => {
                element.resetAlignment();
            }, "alignment");
            groupAlignVertical.add(buttonAlignCenterV);

            //Alignment Bottom
            icon = '<img class="invert popup_padding" src="'+this.controller.config.imagePath+'align_vertical_bottom_white_24dp.svg">';
            let buttonAlignBottom = new PopupToggle(this, "Ausrichtung", icon, (element.align == "bottom"), 
            () => {
                element.setAlignment("bottom");
                element.moveRelative(0,0);
            },
            () => {
                element.resetAlignment();
            }, "alignment");
            groupAlignVertical.add(buttonAlignBottom);

            //Add groups to popup
            this.addElemenent(groupAlignHorizontal);
            this.addElemenent(groupAlignVertical);
        }

        //Delete
        if(!element.blockedActions.includes("remove") && this.controller.config.init.text.removeRows == "true"){
            icon = '<img class="invert popup_padding" src="'+this.controller.config.imagePath+'delete_white_24dp.svg">';
            button = new PopupToggle(this, "Löschen", icon, false, 
            () => {
                if(this.controller.textMinimum()){
                    window.alert("Bereits minimale Zeilenanzahl für den Stempel erreicht: "+this.controller.config.init.text.minRows)
                    return;
                }
                this.controller.deleteElement(this.element);
                this.close();
            },
            () => {
                //
            });
            this.addElemenent(button);
        }

        //Stretch slider and group
        if(!element.blockedActions.includes("changeStretch") && this.controller.config.init.text.stretchRows == "true"){
            let groupStretch = new PopupGroup(this);
            let sliderStretch = new PopupSlider(this, "Strecken", 50, 150, 10, element.stretch, 
            (slider: HTMLInputElement) => {
                element.setStretch(slider.valueAsNumber);
                this.controller.update(false, true, true);
            });
            groupStretch.add(sliderStretch);
            this.addElemenent(groupStretch);
        }

        

        
        
    }

    loadDateMenu(){
        this.addMCIColorPick();
    }

    loadImageMenu(){
        let element = this.element as DesignerImage;

        if(element.static){
            this.addMCIColorPick();
            return;
        }

        //Rotate
        let icon = '<img class="invert" src="'+this.controller.config.imagePath+'refresh_white_24dp.svg">';
        let buttonRotate = new PopupAction(this, "Ausrichtung", icon, 
        () => {
            element.setRotation(element.rotation+45);
        });

        if(!element.blockedActions.includes("move")){
            this.addElemenent(buttonRotate);
        }
        

        this.addMCIColorPick();
        
        //Delete
        let html = '<img class="invert popup_padding" src="'+this.controller.config.imagePath+'delete_white_24dp.svg">';
        let button = new PopupToggle(this, "Löschen", html, false, 
        () => {
            this.controller.deleteElement(this.element);
            this.close();
        },
        () => {
            //
        });
        if(!element.blockedActions.includes("remove")){
            this.addElemenent(button);
        }
        
    }

    loadRectMenu(){
        let element = this.element as DesignerRect;

        //Rotate
        let icon = '<img class="invert" src="'+this.controller.config.imagePath+'refresh_white_24dp.svg">';
        let buttonRotate = new PopupAction(this, "Ausrichtung", icon, 
        () => {
            element.rotate();
        });
        if(!element.blockedActions.includes("move")){
            this.addElemenent(buttonRotate);
        }

        //Group 1
        let groupRectSettings = new PopupGroup(this);

        let length = element.getLength();
        length /= this.controller.config.pixelToMM;

        let lineLength = new PopupNumber(this, "Länge", length, 100, 1, 0.25,
        (evt: Event) => {
            let target = evt.target as HTMLInputElement;
            element.setLength(target.valueAsNumber * this.controller.config.pixelToMM);
            this.controller.update(false, true, true);
        });
        groupRectSettings.add(lineLength);


        let width = element.getWidth();
        width /= this.controller.config.pixelToMM;

        let lineWidth = new PopupNumber(this, "Stärke", width, 100, 0.25, 0.05,
        (evt: Event) => {
            let target = evt.target as HTMLInputElement;
            element.setWidth(target.valueAsNumber * this.controller.config.pixelToMM);
            this.controller.update(false, true, true);
        });
        groupRectSettings.add(lineWidth);
        
        this.addElemenent(groupRectSettings);

        this.addMCIColorPick();

        //Delete
        let html = '<img class="invert" src="'+this.controller.config.imagePath+'delete_white_24dp.svg">';
        let button = new PopupToggle(this, "Löschen", html, false, 
        () => {
            this.controller.deleteElement(this.element);
            this.close();
        },
        () => {
            //
        });
        this.addElemenent(button);
    }

    addMCIColorPick(){
        if(!this.controller.MCI){
            return;
        }
        let icon = '<img src="'+this.controller.config.imagePath+'colors_fill_small.svg">';
        let button = new PopupToggle(this, "Textfarbe", icon, false, 
        () => {
            this.submenuToggle = button;
            this.openMCISubmenu();
        },
        () => {
            this.closeMCISubmenu();
        });
        button.addClass("mci_toggle_button");

        let img = button.div.querySelector('img');
        if(img && this.element.color){
            img.style.borderColor = this.element.color;
        }

        this.addElemenent(button);
    }

    openMCISubmenu(){
        let MCIMenu = new PopupSubmenu(this, "designer_popup_mci");

        for(let key in this.controller.MCIColors){
            let color:ColorObject = this.controller.MCIColors[key];

            let colorButton = document.createElement('div');
            colorButton.classList.add('mci_color_button');
            colorButton.style.backgroundColor = color.hex;
            colorButton.dataset.color = color.hex;
            colorButton.dataset.colorName = color.name;
            colorButton.title = color.name;

            colorButton.addEventListener('click', (evt) => {
                if(!evt.target){
                    return;
                }
                let target = (evt.target as HTMLDivElement);
                let buttonColor = target.dataset.color;
                if(buttonColor){
                    this.element.setColor(buttonColor);

                    //Set active buttons and highlight
                    let otherButtons = this.controller.designerDiv.querySelectorAll('.mci_color_button');
                    for(let otherButton of otherButtons){
                        otherButton.classList.remove('active');
                    }
                    colorButton.classList.add('active');
                    let toggle = this.findElement('mci_toggle_button');
                    if(toggle){
                        let img = toggle.div.querySelector('img');
                        if(img){
                            img.style.borderColor = buttonColor;
                        }
                    }
                    
                    this.controller.update(true, true, true);

                    this.closeMCISubmenu();
                }
            });

            if(this.element.color == color.hex){
                colorButton.classList.add('active');
            }

            MCIMenu.div.appendChild(colorButton);
        }
        this.setSubmenu(MCIMenu);
    }

    closeMCISubmenu(){
        this.closeSubmenu();
    }
}

class Hover extends Popup{
    constructor(DesignerController: DesignerController, element: DesignerElement, x: number, y:number, yOffset: number, text: string){
        super(DesignerController, element, x,y, yOffset);

        this.div.classList.add("designer_popup_hover");
        let icon = new PopupIcon(this, DesignerController.config.imagePath+"warning_amber_24dp.svg", "24px");
        this.addElemenent(icon);

        this.addElemenent( new PopupSpacer(this,2));
        
        this.div.classList.add("designer_popup_hover");
        let textElement = new PopupText(this, text, "Futura", "16px");
        this.addElemenent(textElement);

        for(let el of this.menuElements){
            this.div.appendChild(el.div);
        }

        

        this.center();
    }
}

class PopupElement{
    popup: Popup;
    div: HTMLElement;
    constructor(popup:Popup, description: string, CSSClass: string){
        this.popup = popup;
        this.div = document.createElement("div");
        if(CSSClass){
            this.div.classList.add(CSSClass);
        }
        this.div.title = description;
    }

    addClass(extraClass: string){
        this.div.classList.add(extraClass);
    }
}

class PopupSubmenu extends PopupElement{
    elements:Array<PopupElement> = [];
    constructor(popup:Popup, specialClass: string){
        super(popup,"","popup_submenu");
        this.addClass(specialClass);
    }
}

class PopupGroup extends PopupElement{
    elements:Array<PopupElement> = [];
    constructor(popup:Popup){
        super(popup,"","popup_group");
    }

    add(ele: PopupElement){
        this.elements.push(ele);
        this.div.appendChild(ele.div);
    }
}

class PopupSpacer extends PopupElement{
    space: number;
    constructor(popup:Popup, space:number){
        super(popup,"","popup_element_spacer")
        this.space = space;
        this.div.style.width = space+"px";
    }
}

class PopupText extends PopupElement{
    text: string;
    font: string;
    size: string;
    constructor(popup:Popup, text:string, font: string, size: string){
        super(popup,"","")
        this.text = text;
        this.div.innerHTML = text;
        this.div.style.font = font;
        this.div.style.fontSize = size;
    }
}

class PopupIcon extends PopupElement{
    url: string;
    size: string;
    constructor(popup:Popup, url: string, size: string){
        super(popup,"","popup_element_icon")
        this.div.style.backgroundImage = "url("+url+")";
        this.div.style.width = size;
        this.div.style.height = size;
    }
}

class PopupToggle extends PopupElement{
    on:boolean = false;
    content: string;
    toggleFunctionOn: () => void;
    toggleFunctionOff: () => void;
    group: string;
    constructor(popup:Popup, description: string, content: string, startState = false, toggleFunctionOn: () => void,  toggleFunctionOff: () => void, group:string|null = null){
        super(popup, description, "popup_element_toggle");
        this.on = startState;
        this.content = content;
        this.toggleFunctionOn = toggleFunctionOn;
        this.toggleFunctionOff = toggleFunctionOff;
        this.div.innerHTML = content;
        this.div.addEventListener("click", () => { this.click(); });
        if(group){
            this.group = group;
        }

        if(this.on){
            this.toggleOn(false);
        }
    }

    click(){
        this.on = !this.on;
        if(this.on){
            this.toggleOn();
        }else{
            this.toggleOff();
        }
    }

    toggleOn(execute = true){
        if(this.group){
            for(let element of this.popup.menuElements){
                //Go deeper for groups
                if(element instanceof PopupGroup ){
                    for(let groupElement of element.elements){
                        if(groupElement instanceof PopupToggle && groupElement.group == this.group){
                            groupElement.toggleOff(execute);
                        }
                    }
                }
            }
        }
        this.div.classList.add("popup_element_toggle_active");
        if(execute){
            this.toggleFunctionOn();
            this.popup.controller.startUndoTimer();
        }
        
        this.on = true;
    }

    toggleOff(execute = true){
        this.div.classList.remove("popup_element_toggle_active");
        if(execute){
            this.toggleFunctionOff();
            this.popup.controller.startUndoTimer();
        }
        
        this.on = false;
    }
}

class PopupSlider extends PopupElement{
    slider: HTMLInputElement;
    description: string;
    changeFunction: (slider: HTMLInputElement) => void;
    constructor(popup:Popup, description: string, minValue: number, maxValue: number, stepSize: number, value: number, changeFunction: (slider: HTMLInputElement) => void){
        super(popup, description, "popup_element_slider");
        this.changeFunction = changeFunction;

        let id = "slider_"+description;

        //Label
        let label = document.createElement("label");
        label.innerHTML = description + ":";
        label.setAttribute("for", id);
        this.div.appendChild(label);

        //Range
        let slider = document.createElement("input");
        slider.type = "range";
        slider.min = minValue.toString();
        slider.max = maxValue.toString();
        slider.step = stepSize.toString();
        slider.value = value.toString();
        slider.classList.add("form-control-range");
        this.description = description;
        slider.addEventListener("input", () => { 
            this.change();
        });

        //Ticks
        let datalist = document.createElement("datalist");
        datalist.id = id;
        for(let i = minValue; i <= maxValue; i += stepSize){
            let option = document.createElement("option");
            option.value = i.toString();
            datalist.appendChild(option);
        }
        slider.setAttribute("list", id);
        this.div.appendChild(datalist);

        this.slider = slider;
        this.div.appendChild(slider);

        this.change();
    }

    change(){
        this.changeFunction(this.slider);
        this.slider.title = this.description+": "+this.slider.value;
    }
}

class PopupAction extends PopupElement{
    content: string;
    toggleFunction: () => void;

    constructor(popup:Popup, description: string, content: string, toggleFunction: () => void){
        super(popup, description, "popup_element_toggle");
        this.content = content;
        this.toggleFunction = toggleFunction;
        this.div.innerHTML = content;
        this.div.addEventListener("click", () => { this.click(); });
    }

    click(){
        this.toggleFunction();
    }
}

class PopupNumber extends PopupElement{
    value: number;
    max: number;
    min: number;
    increment: number;
    changeFunction: (evt: Event) => void
    constructor(popup:Popup, description: string, value: number, max: number, min: number, increment: number, changeFunction: (evt: Event) => void){
        super(popup, description, "popup_element_number");
        this.changeFunction = changeFunction;
        let number = document.createElement("input");
        number.type = "number";
        number.min = min.toString();
        number.max = max.toString();
        number.value = value.toString();
        number.step = increment.toString();
        number.title = description;
        number.addEventListener("change", (evt: Event) => { this.change(evt); });
        this.div.appendChild(number);
    }

    change(evt: Event){
        this.changeFunction(evt);
    }
}

export { Popup, ElementTools, Hover, PopupElement, PopupSubmenu, PopupGroup, PopupSpacer, PopupText, PopupIcon, PopupToggle, PopupAction, PopupNumber };