import { DesignerController } from "./DesignerController";
import { Alignment, Anchor, DesignerDate, DesignerElement, DesignerImage, DesignerText } from "./DesignerElements";
import { Utility, SelectedDragOffset } from "./Utility";

//Handle user input
class Input{
    controller: DesignerController;
    mouseIsDown = false;
    anchorDragging: Anchor|null = null;
    selectedDragOffset = new SelectedDragOffset();
    elementSelected: DesignerElement|null = null;
    elementHover: DesignerElement|null = null;

    checkForDeselect = false;
    downScrollY = 0;

    constructor(controller: DesignerController){
        this.controller = controller;
    }

    //Initialize event listerns for canvas and UI elements
    initListeners(){
        //Mouse-events
        var _this = this;

        document.getElementById('delete_element')?.addEventListener('click', function () {
            if(_this.elementSelected){
                _this.controller.deleteElement(_this.elementSelected);
            }
            
        });

        let tabIcons = document.getElementsByClassName('toolbar_icon');
        for(let tabIcon of tabIcons){
            tabIcon.addEventListener('click', () => {
                if(tabIcon.classList.contains("disabled")){
                    return;
                }
                _this.switchTab(tabIcon.id);
            });
        }

        document.getElementById('toolbar_undo')?.addEventListener('click', (evt) => {
            
            let target = evt.currentTarget as HTMLElement;
            if(target.classList.contains("inactive")){
                return;
            }
            _this.controller.undo();
            this.controller.closeActivePopups();
        });

        document.getElementById('toolbar_redo')?.addEventListener('click', (evt) => {
            let target = evt.target as HTMLElement;
            if(target.classList.contains("inactive")){
                return;
            }
            _this.controller.redo();
            this.controller.closeActivePopups();
        });
        

        document.getElementById('text_val')?.addEventListener('input', function (evt) {
            //Only update Text when Text Element selected
            if(_this.elementSelected instanceof DesignerText){
                let textVal = (evt.target as HTMLInputElement).value;
                _this.elementSelected.setText(textVal);
            }
        });

        window.addEventListener('keydown', function (evt){
            _this.keyPress(evt);  
        });

        document.getElementById('zoom_slider')?.addEventListener('change', function (evt) {
            let zoom = Number.parseInt( (evt.target as HTMLInputElement)?.value);
            _this.controller.changePixelScaling(zoom, true);
            _this.controller.setCanvasDimensions(false);
        });

        document.getElementById('scale_slider')?.addEventListener('input', function (evt) {
            let scale = Number.parseFloat( (evt.target as HTMLInputElement)?.value);
            _this.controller.scaleContent(scale);
        });

        document.getElementById('scale_fill')?.addEventListener('click', () => {
            _this.controller.scaleFill();
        });

        

        document.getElementById('showgrid_checkbox')?.addEventListener('change', function (evt) {
            if (!evt.currentTarget) return;
            if ( (evt.currentTarget as HTMLInputElement) .checked) {
                _this.controller.showGrid = true;
            }else{
                _this.controller.showGrid = false;
            }
        });

        document.getElementById('snapgrid_checkbox')?.addEventListener('change', function (evt) {
            if (!evt.currentTarget) return;
            if ( (evt.currentTarget as HTMLInputElement) .checked) {
                _this.controller.snapToGrid = true;
            }else{
                _this.controller.snapToGrid = false;
            }
        });

        document.getElementById('delete_all')?.addEventListener('click', function (evt) {
            let dialog = _this.controller.dialogInterface.addDialog("Gestaltung wirklich löschen?", "", "Ja", "Nein");

            dialog.show().then( () => {
                _this.controller.clearElements(true, false);
            });
        });

        let button = document.getElementById('generate_preview') as HTMLDivElement;
        button?.addEventListener('click', (evt) => {
            let url = button.dataset.url;
            if(url){
                button.dataset.url = "";
                button.classList.remove("pdf_ready");
                let span = button.getElementsByTagName("span")[0];
                span.textContent = "PDF-Druckvorschau";
                window.open(url);
            }else{
                if(!button.classList.contains("pdf_loading")){
                    _this.controller.generatePreview(button);
                }
            }
        });

        let finishButtons = document.getElementsByClassName('finish');
        for(let finishButton of finishButtons){
            finishButton.addEventListener('click', function () {
                _this.controller.finish();
            });
        }

        window.addEventListener('resize', function () {
            _this.controller.checkCanvasFits();
        });
        
        this.initUniversalListeners();
    }

    //Unviersal input events
    initUniversalListeners(){
        var _this = this;

        this.controller.designerDiv.addEventListener('pointerdown', ({ target }) => {
            if(target){
                let element = target as HTMLDivElement;
                //Only close popup when not clicking on it or a designer element
                let popup = element.closest('#designer-canvas');
                let popup2 = element.closest('.designer_popup_menu');
                if(!popup && !popup2){
                    //Also dont close while scrolling (Mobile)
                    this.checkForDeselect = true;
                    this.downScrollY = window.scrollY;
                }
            }
        });

        this.controller.designerDiv.addEventListener('pointerup', function (evt) {
            if(_this.checkForDeselect){
                _this.checkDeselect();
            }
        });

        this.controller.canvas.addEventListener('pointerdown', function (evt) {
            _this.mouseDown(evt);
        });

        this.controller.canvas.addEventListener('pointerup', function (evt) {
            _this.mouseUp(evt);
        });

        this.controller.canvas.addEventListener('pointerleave', function () {
            _this.mouseLeave();
        });

        this.controller.canvas.addEventListener('pointermove', function (evt) {
            _this.mouseMove(evt);
        });

        /*scaling */
        
        window.addEventListener('keydown', function (evt) {
            /*
            if(evt.key == "ArrowUp"){
                _this.controller.scaleContent( Math.min(_this.controller.scaling + 0.1, 2) );
            }
            if(evt.key == "ArrowDown"){
                _this.controller.scaleContent( Math.max(_this.controller.scaling - 0.1, 0.5) );
            }*/
        });
        
        
    }

    //Change activ tab (left side)
    async switchTab(tabID: string, noWarning = false){
        this.switchTabFilling(tabID, noWarning).then(
            //Ja
            (filling) => {
                if(filling){
                    filling = filling as DesignerImage;
                    filling.delete();
                }

                let welcomeDIV = document.getElementsByClassName("welcome_screen")[0];
                if(welcomeDIV && this.controller.welcomeData != null){
                    this.controller.patternController.loadStartPattern(this.controller.welcomeData, false);
                    this.controller.closeWelcomeScreen();
                }

                if(tabID == 'toolbar_text' && this.controller.config.init.text.maxRows == 0 && this.controller.config.init.text.minRows <= 0){
                    return;
                }
                if(tabID == 'toolbar_image' && this.controller.config.init.images.allowed == "false"){
                    return;
                }
        
                switch(tabID){
                    case 'toolbar_text': this.controller.tabInterface.activeTab = "text"; break;
                    case 'toolbar_pattern': this.controller.tabInterface.activeTab = "pattern"; break;
                    case 'toolbar_userpattern': this.controller.tabInterface.activeTab = "userpattern"; break;
                    case 'toolbar_image': this.controller.tabInterface.activeTab = "image"; break;
                    case 'toolbar_border': this.controller.tabInterface.activeTab = "border"; break;
                    case 'toolbar_full': this.controller.tabInterface.activeTab = "full"; break;
                    case 'toolbar_qr': this.controller.tabInterface.activeTab = "qr"; break;
                }
                this.controller.tabInterface.showActiveTab();
            }
        );
    }

    async switchTabFilling(tabID: string, noWarning = false){
        if(!noWarning){
            let filling = this.controller.hasFillingImage();
            if(filling && tabID != "toolbar_full"){
                let dialog = this.controller.dialogInterface.addDialog("Wollen Sie zur freien Gestaltung wechseln?", "Dadurch wird Ihre hochgeladene Gestaltung gelöscht!", "Fortfahren", "Abbrechen");
                return dialog.show(filling)
            }
        }
        return new Promise( (resolve, reject) => {
            resolve(null);
        });
    }

    //General keypress managment
    keyPress(evt: KeyboardEvent){
        if(this.elementSelected){
            //Only when manual movement is allowed
            if( this.controller.config.init.text.freePositioning == "false" && this.elementSelected instanceof DesignerText){
                return;
            }
            let increment = this.controller.config.init.arrowKeysStep*this.controller.config.pixelToMM;
            if(this.controller.showGrid == true && this.controller.snapToGrid){
                increment = this.controller.gridSpaceing*this.controller.config.pixelToMM;
            }
            if(evt.key == "ArrowDown"){
                this.elementSelected.moveRelative(0,increment, true, false, true, true);
                this.elementSelected.changed = true;
            }
            if(evt.key == "ArrowUp"){
                this.elementSelected.moveRelative(0,-increment, true, false, true, true);
                this.elementSelected.changed = true;
            }
            if(evt.key == "ArrowRight"){
                this.elementSelected.moveRelative(increment,0, true, false, true, true);
                this.elementSelected.changed = true;
            }
            if(evt.key == "ArrowLeft"){
                this.elementSelected.moveRelative(-increment,0, true, false, true, true);
                this.elementSelected.changed = true;
            }
        }
    }

    //Mouse button down
    mouseDown(evt: MouseEvent){
        this.mouseIsDown = true;

        let realX = evt.offsetX/this.controller.zoom;
        let realY = evt.offsetY/this.controller.zoom;
        this.selectElement(realX, realY);
        if(this.checkForDeselect){
            this.checkDeselect();
        }
    }

    //Mouse button up
    mouseUp(evt: MouseEvent){
        this.mouseIsDown = false;
        this.anchorDragging = null;
        if(this.checkForDeselect){
            this.checkDeselect();
        }
    }

    mouseLeave(){
        this.mouseIsDown = false;
        this.anchorDragging = null;
    }

    //Mouse moved
    mouseMove(evt: MouseEvent){
        let realX = evt.offsetX/this.controller.zoom;
        let realY = evt.offsetY/this.controller.zoom;
        this.processMove(realX, realY);
    }

    //Touch moved
    touchMove(evt: TouchEvent){
        if(!evt.target){
            return;
        }
        let target = evt.target as HTMLElement;
        var rect = target.getBoundingClientRect();
        let touch = evt.targetTouches[0];
        let x = touch.pageX - rect.left;
        let y = touch.pageY - rect.top;
        let realX = x/this.controller.zoom;
        let realY = y/this.controller.zoom;
        this.processMove(realX, realY);
    }

    processMove(realX: number, realY: number){
        //Warning hover
        let hover = null;
        for(let element of this.controller.elements){
            if(!element.error){
                continue;
            }
            let box = element.getWarningBox();
            let collision = Utility.pointInBox(box,realX,realY);
            
            if(collision){
                hover = element; 
                break;
            }
        }

        if(hover){
            this.elementHover = hover;
            if(hover.error){
                let msg = "<b>Warnung!</b> "+Utility.getErrorMessage(hover.errorType);
                this.controller.openHover(hover, msg);
            }
        }else{
            this.controller.closeActiveHover();
            this.elementHover = null;
        }

        //Element move & image scaling
        if(this.mouseIsDown && this.elementSelected !== null){
            let realXOffset = realX-this.selectedDragOffset.x;
            let realYOffset = realY-this.selectedDragOffset.y;
            if(realXOffset == 0 && realYOffset == 0){
                return;
            }
            if(this.anchorDragging){
                this.anchorDragCorner(realX, realY, realXOffset, realYOffset);
                this.elementSelected.changed = true;
                return;
            }
            if( this.elementSelected.blockedActions.includes("move") ){
                return;
            }
            if( this.controller.config.init.text.freePositioning == "false" && this.elementSelected instanceof DesignerText){
                return;
            }
            this.elementSelected.move(realXOffset,realYOffset, true, true, false, true);
            this.elementSelected.changed = true;
        }
    }

    //Resize image proportional based on anchor dragging
    //Real = poisiton on canvas
    //Offset = relative movement
    anchorDragCorner(realX: number, realY: number, offsetX:number, offsetY: number): boolean{
        if(!this.anchorDragging || !this.elementSelected){
            return false;
        }

        let ele = this.elementSelected as DesignerImage;
        ele.anchorDragResize(this.anchorDragging, realX, realY, this.selectedDragOffset);
        if(this.controller.activeElementTools){
            let element = this.controller.activeElementTools.element;
            let elementBounds = element.getBounds();
            this.controller.activeElementTools.move(elementBounds.x+(elementBounds.w/2), elementBounds.y);
        }
        this.controller.centerContent();
        this.controller.update(false);
        return true;
    }

    //Check for elements at position XY, if found, select
    selectElement(x: number, y: number){
        //reverse elements because newer ones are ontop
        let elements_rev = this.controller.elements.slice().reverse();

        //Image resizing
        if(this.elementSelected instanceof DesignerImage && this.elementSelected.static == false){
            for(let anchor of this.elementSelected.anchors){
                let anchorSize = anchor.size;
                let halfSize = anchorSize / 2;

                //collision
                let selectedBounds = this.elementSelected.getBounds();
                let pos = anchor.getPosition();
                let left = pos.x + selectedBounds.x - halfSize < x;
                let right = pos.x + selectedBounds.x + halfSize > x;
                let top = pos.y + selectedBounds.y - halfSize < y;
                let bottom = pos.y + selectedBounds.y + halfSize > y;

                if(left && right && top && bottom){
                    this.anchorDragging = anchor;
                    this.selectedDragOffset.x = x;
                    this.selectedDragOffset.y = y;
                    return;
                }
            }
            
        }

        for(let element of elements_rev){
            if(element.static){
                continue;
            }

            //Minimum bounds for easier selection
            let eleBounds = element.getBounds();
            let eleX = eleBounds.x;
            let eleY = eleBounds.y;
            let eleW = eleBounds.w;
            let eleH = eleBounds.h;
            
            if(eleW < this.controller.config.selectMinWidth){
                let diff = this.controller.config.selectMinWidth-eleW;
                eleX -= diff/2;
                eleW += diff;
            }
            if(eleH < this.controller.config.selectMinHeight){
                let diff = this.controller.config.selectMinHeight-eleH;
                eleY -= diff/2;
                eleH += diff;
            }


            //If click was inside element bounds, mark it as selected
            if(eleX <= x && eleY <= y && eleX+eleW >= x &&eleY+eleH >= y){
                console.log(element);

                /* DEBUG TEST
                if(element instanceof DesignerText){
                    let prompt = window.prompt("Spacing", "0");
                    if(prompt != null){
                        let spacing = Number.parseInt(prompt);
                        element.setLetterSpacing(spacing);
                    }
                }

                if(element instanceof DesignerText){
                    let prompt = window.prompt("Stretch", "100");
                    if(prompt != null){
                        let spacing = Number.parseInt(prompt);
                        element.setStretch(spacing);
                    }
                }*/
                if(element instanceof DesignerDate){
                    this.elementSelected = element;
                    this.controller.openElementToolsOnElement(element);
                    return;
                }

                if(!element.blockedActions.includes("select")){
                    this.elementSelected = element;
                    this.selectedDragOffset.x = x-element.x;
                    this.selectedDragOffset.y = y-element.y;
                    this.controller.openElementToolsOnElement(element);

                    //Automatically change tab based on selected element
                    if(element instanceof DesignerText && !(element instanceof DesignerDate)){
                        this.switchTab("toolbar_text");
                        this.controller.tabInterface.highlightText(element.id);
                    }
                    if(element instanceof DesignerImage){
                        this.switchTab("toolbar_image");
                    }
                    return;
                }else{
                    if(this.controller.MCI){
                        this.elementSelected = element;
                        this.controller.openElementToolsOnElement(element);
                        return;
                    }
                }
            }
        }
        this.checkForDeselect = true;
        this.downScrollY = window.scrollY;
    }

    //check if the toolbar should be closed
    checkDeselect(){
        if(this.downScrollY == window.scrollY){
            this.elementSelected = null;
            this.controller.closeActiveElementTools();
            this.controller.tabInterface.unhighlightText();
        }
        this.checkForDeselect = false;
    }
}

export {Input};