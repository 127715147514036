import { convertToObject } from "typescript";
import { DesignerController } from "./DesignerController";
import { DesignerDate, DesignerElement, DesignerImage, DesignerRect, DesignerText } from "./DesignerElements";
import { Alignment, BlockedActions, Border, BorderLegacy, LegacySaveDataElement, SaveDataDate, SaveDataImage, SaveDataRect, SaveDataText, SaveObject, TemplateObject, UserTemplateObject } from "./TypescriptInterfaces";
import { Utility } from "./Utility";

class DesignerPatternController{
    controller: DesignerController;

    constructor(controller: DesignerController){
        this.controller = controller;
    }

    //Load local or init pattern
    loadStartPattern(data: any, loadLocal: boolean){
        //Try loading local - if not, load init
        if(loadLocal){
            if( this.loadLocalPattern() != false){
                return;
            }
        }

        if(data.template && data.template.canvas){
            let template:TemplateObject = {
                data: data.template.canvas,
                border: data.template.border,
                pad: data.template.pad
            }
            this.loadTemplate(template);
        }
    }

    //Load the pattern in local storage - last edited pattern
    loadLocalPattern(){
        if(this.controller.localStoragePattern){
            let data:SaveObject = JSON.parse(this.controller.localStoragePattern);

            //apply saved offset if it can fit

            if(data.metadata.offset && data.metadata.dimension && this.offsetFits(data.metadata.dimension.w, data.metadata.dimension.h, data.metadata.offset)){
                this.loadSaveData(data, true, true, true, true, true);
                this.controller.addHistoryPoint(false);
            }else{
                this.loadSaveData(data, true, true, true, true, false);
                this.controller.addHistoryPoint(false);
            }

            return true;
        }
        return false;
    }

    //Check if pattern fits into design area with offset applied
    offsetFits(w: number, h: number, offset: {x: number, y: number}){
        w += offset.x;
        h += offset.y;

        if(w <= this.controller.config.maxWidthMM && h <= this.controller.config.maxHeightMM){
            return true;
        }
        return false;
    }

    //Pattern handeling
    //Load a specific template based on JSON
    loadTemplate(template: TemplateObject, overwrite = true, overwriteBorder = true, center = true){
        console.log(template.data);
        let data: SaveObject;
        this.controller.scaling = 1;
        if(!template.data || template.data == ""){
            console.error("No canvas data to load");
            return false;
        }
        //Parse canvas content
        try{
            data = JSON.parse(template.data);
        }catch{
            console.error("Couldn't parse JSON data");
            return false;
        }

        //Fallback for old save format
        if(template.pad && template.pad != ""){
            data.pad = template.pad;
        }

        if(data.metadata){
            if(data.metadata.offset && data.metadata.dimension && this.offsetFits(data.metadata.dimension.w, data.metadata.dimension.h, data.metadata.offset)){
                this.loadSaveData(data, true, true, center, true, true);
                this.controller.addHistoryPoint(false);
            }else{
                this.loadSaveData(data, true, true, center, true, false);
                this.controller.addHistoryPoint(false);
            }
            return true;
        }
        this.loadSaveDataLegacy(template, overwrite, overwriteBorder, center);
        this.controller.addHistoryPoint(false);

        //Apply pad color
        if(!this.controller.MCI){
            for(let element of this.controller.elements){
                element.setColor(this.controller.inkpadColor);
            }
        }

        return true;
    }

    overwriteBase(overwriteElements: DesignerElement[], overwriteText: DesignerText){
        if(overwriteText != null){
            //Overwrite all texts with base
            for(let element of overwriteElements){
                if(element instanceof DesignerText && !(element instanceof DesignerDate)){
                    this.overwriteTextElement(element, overwriteText);
                }
            }
            return true;
        }
        return false;
    }

    //Load save data in the new format
    loadSaveData(saveData: SaveObject, overwriteText = true, overwriteBorder = true, center = true, localLoad = false, useOffset = false){
        let metadataVersionNumber = Number.parseFloat( saveData.metadata.formatVersion );
        if(saveData.metadata.formatVersion != this.controller.formatVersion){
            console.warn("Saved format not matching current format, loading might fail. ("+saveData.metadata.formatVersion+" vs "+this.controller.formatVersion+" )");
        }

        if(overwriteText){
            this.controller.clearElements(false, true);
        }

        if(saveData.border && overwriteBorder){
            this.controller.setBorder(saveData.border);
        }

        if(saveData.pad){
            this.controller.inkpadColor = saveData.pad;
        }
        
        let elemenentBaseOverwrite = [...this.controller.elements]
        let lastTextElement: DesignerText|null = null;

        let editAreaSize = this.controller.editAreaSize();
        for(let element of saveData.elements){
            let existingElement: DesignerText | false = false;
            if(!overwriteText){
                //Skip non text elements
                if(element.type != "text"){
                    continue;
                }
                let found = this.controller.findElementByPatternID(element.patternID);
                //Skip applying pattern for elements that don't already exist nad have a patternID
                if(!found){
                    continue;
                }
                existingElement = found;

                //Remove elements that are already being overwritten from being overwritten later
                let index = elemenentBaseOverwrite.indexOf(existingElement);
                elemenentBaseOverwrite.splice(index,1);
            }
            
            //Add offset (UI + pattern size)
            let x = element.x * this.controller.config.pixelToMM;
            let y = element.y * this.controller.config.pixelToMM;
            x += editAreaSize.minX;
            y += editAreaSize.minY;

            if(saveData.metadata.offset && useOffset){
                let offsetX = saveData.metadata.offset.x  * this.controller.config.pixelToMM;
                let offsetY = saveData.metadata.offset.y  * this.controller.config.pixelToMM;
                //Fallback, wrong save format for offset used ...
                if(saveData.metadata.formatVersion == "2.1"){
                    console.log("Using fallback offset, offset may be wrong.");
                    x += offsetX/2;
                    y += offsetY/2;
                }else{
                    x += offsetX;
                    y += offsetY;
                }
            }

            

            //If text element
            if(element.type == "text"){
                let saveDataText = element as SaveDataText;

                let textElement = new DesignerText(this.controller, x, y, saveDataText.text, saveDataText.size, saveDataText.font, saveDataText.align, saveDataText.rotation);
                textElement.setBold(saveDataText.bold);
                textElement.setItalic(saveDataText.italic);
                textElement.setUnderline(saveDataText.underline);
                textElement.static = saveDataText.static;
                textElement.blockedActions = saveDataText.blockedActions;
                //Format 2.4 has stretch
                if(saveDataText.stretch){
                    textElement.setStretch(saveDataText.stretch);
                }

                if(saveDataText.patternID){
                    textElement.patternID = element.patternID;
                }
                if(!overwriteText && existingElement){
                    let oldText = existingElement.text;
                    let oldX = existingElement.x;
                    let oldY = existingElement.y;
                    textElement.text = oldText;
                    textElement.x = oldX;
                    textElement.y = oldY;
                    this.overwriteTextElement(existingElement,textElement);
                }else{
                    textElement.setDimensions();
                    textElement.addElement(false,false);
                }
                if(saveDataText.color){
                    //If current product supports MCI, use the color from the pattern
                    if(this.controller.MCI){
                        textElement.setColor(saveDataText.color);
                    }else{
                        //If not, use the pad color
                        textElement.setColor(this.controller.inkpadColor);
                    }
                }

                //2.3 uses center as origin instead of top left corner
                if(metadataVersionNumber < 2.3){
                    textElement.getDimensions();
                    textElement.y += textElement.h / 2;
                    textElement.x += textElement.w / 2;
                }

                lastTextElement = textElement;
            }
            
            //If image element
            if(element.type == "image"){
                let saveData = element as SaveDataImage;

                let w = saveData.w * this.controller.config.pixelToMM;
                let h = saveData.h * this.controller.config.pixelToMM;

                let color = "#000000";
                if(saveData.color){
                    color = saveData.color;
                }

                let imageElement = new DesignerImage(this.controller, x, y, saveData.url, saveData.greyscale, color, w, h);

                imageElement.static = saveData.static;
                imageElement.filling = saveData.filling;
                imageElement.blockedActions = saveData.blockedActions;
                imageElement.addElement(false, false, !saveData.filling);
                if(saveData.filling){
                    imageElement.x = x;
                    imageElement.y = y;
                }
                
            }
            //If rect element
            if(element.type == "rect"){
                let saveData = element as SaveDataRect;

                let w = saveData.w * this.controller.config.pixelToMM;
                let h = saveData.h * this.controller.config.pixelToMM;

                let rect = new DesignerRect(this.controller, x, y, w, h, saveData.color, saveData.orientation);

                rect.static = saveData.static;
                rect.blockedActions = saveData.blockedActions;
                rect.addElement(false,false);

                if(saveData.color){
                    rect.setColor(saveData.color);
                }
            }
            //Date element - sppecial case
            if(element.type == "date"){
                if(localLoad){
                    continue;
                }
                let saveData = element as SaveDataDate;

                let w = saveData.w*this.controller.config.pixelToMM;
                let h = saveData.h*this.controller.config.pixelToMM;

                let date = new DesignerDate(this.controller, x, y, w, h, saveData.text, saveData.size, saveData.font);
                date.makeStatic();
                date.setDimensions();
                date.addElement(false, false);
                if(saveData.color){
                    date.setColor(saveData.color);
                }
            }
        }
        
        if(lastTextElement != null){
            this.overwriteBase(elemenentBaseOverwrite, lastTextElement);
        }

        if(localLoad){
            this.loadDateLegacy();
        }


        if(center){
            if(!this.dateIsSet()){
                this.controller.centerContent(false);
            }
            //this.controller.alignElements();
        }

        this.controller.tabInterface.renderTextTab();

        if(this.controller.hasFillingImage()){
            this.controller.input.switchTab("toolbar_full", true);
        }
    }

    loadDateField(data: any){
        if(data.template && data.template.canvas){
            if(data.dater){
                this.controller.dater = {
                    width: Number.parseFloat(data.dater.width),
                    height: Number.parseFloat(data.dater.height),
                    x: Number.parseFloat(data.dater.x),
                    y: Number.parseFloat(data.dater.y),
                    content: data.dater.content,
                    "font-size": Number.parseFloat(data.dater['font-size']),
                    font: data.dater.font
                }
            }
        }
    }

    //Generte text element from legacy properties
    buildTextFromLegacyData(element: LegacySaveDataElement){
        let editAreaSize = this.controller.editAreaSize();

        let x = element.x * this.controller.config.pixelToMM;
        let y = element.y * this.controller.config.pixelToMM;

        x += editAreaSize.minX;
        y += editAreaSize.minY;

        if(this.controller.border){
            if(this.controller.border.width){
                //y += this.controller.border.width * this.controller.config.pixelToMM;
            }
            if(this.controller.border.padding){
                //y += this.controller.border.padding * this.controller.config.pixelToMM;
            }
        }

        //element.fontSize is converted twice for some reason
        //one pt2mm still returns pts
        let fontSize = Utility.pt2mm(element.fontSize);
        /*
        let sizeSteps = 1/this.controller.config.fontIncrement;
        fontSize = Math.round(fontSize * sizeSteps)/sizeSteps;
        y -= pt2px(fontSize, this.controller.config.pixelToMM);*/

        //Alignment on old designer top,centerV,bottom are just temporary movements, not saved!
        let align:Alignment = null;
        if(element.dataAlign == "center"){
            align = "centerH";
        }
        if(element.dataAlign == "left"){
            align = "left";
        }
        if(element.dataAlign == "right"){
            align = "right";
        }

        let rotation = 0;
        if(element.dataRotate){
            rotation = Number.parseFloat(element.dataRotate);
        }

        let textElement = new DesignerText(this.controller, x, y, element.content, fontSize, element.dataFont, align, rotation);
        
        if(element.dataStyle == "bold"){
            textElement.setBold(true);
        }
        if(element.dataStyle == "italic"){
            textElement.setItalic(true);
        }
        if(element.dataStyle == "bolditalic"){
            textElement.setBold(true);
            textElement.setItalic(true);
        }
        if(element.dataUnderline == "true"){
            textElement.setUnderline(true);
        }

        if(element.dataStretch){
            //Old format is 1.0 = 100%, new format 100 = 100%
            textElement.setStretch( element.dataStretch * 100 );
        }

        if(element.id){
            textElement.patternID = element.id;
        }

        //In the old format the y position is textBaseline
        //new format = center
        //Move texts up by exactly 1 height
        let dim = textElement.getDimensions();
        //textElement.y -= dim.h;

        //add boundDescent (height after the baseline) to y to account for y difference
        let offsetHorizontal = textElement.realWidth / 2;
        let offsetVertical = textElement.baselineHeight / 2;

        textElement.y += offsetVertical;
        textElement.x += offsetHorizontal;

        if(textElement.rotation == 0){
            textElement.y -= textElement.h / 2;
        }
        if(textElement.rotation == 90){
            textElement.x += textElement.h / 2;
            textElement.y -= offsetHorizontal;
        }
        if(textElement.rotation == 180){
            textElement.y += textElement.h / 2;
        }
        if(textElement.rotation == 270){
            textElement.x -= textElement.h / 2;
            textElement.y -= offsetHorizontal;
        }

        return textElement;
    }

    //Get last text row in legacy data
    getLastTextFromLegacyData(canvasData:LegacySaveDataElement[]){
        let last = null;
        for(let element of canvasData){
            if(element.type == "text"){
                last = element;
            }
        }
        if(last != null){
            return this.buildTextFromLegacyData(last);
        }
        return null;
    }

    loadLegacyTemplateFromString(templateString: string){
        //Remove escaped quotes
        templateString = templateString.replace(/\\/g,"");

        //If string is wrapped in quotes, remove them
        if(templateString[0] == '"'){
            templateString = templateString.substring(1, templateString.length - 1);
        }

        let template:TemplateObject = {
            data: templateString,
            border: null,
            pad: "#000000"
        }
        console.log(templateString);
        this.loadTemplate(template, true, true, true);
    }

    //Load canvas data with old designer format
    loadSaveDataLegacy(template: TemplateObject, overwriteText = true, overwriteBorder = true, center = true){
        let canvasData:LegacySaveDataElement[] = JSON.parse(template.data);
        let border: BorderLegacy | null = null;

        if(overwriteText){
            this.controller.clearElements(false, false);
        }

        if(overwriteBorder){
            this.controller.setBorder(null);
        }
        
        if(template.border){
            //BORDER SOMETIMES OBJECT SOMETIMES JSON STRING!!!!
            if(typeof template.border == 'string'){
                border = JSON.parse(template.border);
            }
            if(typeof template.border == 'object'){
                border = template.border;
            }

            if(border != null){
                if(border.width && overwriteBorder){
                    let borderSettings: Border = {
                        active: true,
                        padding: border.space ?? 0,
                        width: border.width,
                        double: (border.double > 0),
                        style: "solid",
                        color: border.color ?? "#000000"
                    };
                    this.controller.setBorder(borderSettings);
                }
            }else{
                //console.error("Couldn't loud border data: "+template.border);
            }
            
        }
        
        let elemenentBaseOverwrite = [...this.controller.elements]
        let lastTextElement: DesignerText|null = null;
        let editAreaSize = this.controller.editAreaSize();
        for(let element of canvasData){
            let existingElement: DesignerText | false = false;
            if(!overwriteText){
                //Skip non text elements
                if(element.type != "text"){
                    continue;
                }
                let found = this.controller.findElementByPatternID(element.id);

                //Skip applying pattern for elements that don't already exist and have a patternID
                if(!found){
                    continue;
                }
                existingElement = found;

                //Remove elements that are already being overwritten from being overwritten later
                let index = elemenentBaseOverwrite.indexOf(existingElement);
                elemenentBaseOverwrite.splice(index,1);
            }
            let w = element.width * this.controller.config.pixelToMM;
            let h = element.height * this.controller.config.pixelToMM;
            
            let x = element.x * this.controller.config.pixelToMM;
            let y = element.y * this.controller.config.pixelToMM;
            x += editAreaSize.minX;
            y += editAreaSize.minY;

            let rotation = 0;
            if(element.dataRotate){
                rotation = Number.parseFloat(element.dataRotate);
            }
            //New Element position is center
            if(element.type == "text"){
                let textElement = this.buildTextFromLegacyData(element);
                if(element.dataRights){
                    let blockedActions = this.convertLegacyPermissions(element.dataRights, true);
                    textElement.setBlockedActions(blockedActions);
                }
                if(element.fill){
                    textElement.setColor(element.fill);
                }
                if(!overwriteText && existingElement){
                    textElement.text = existingElement.text;
                    textElement.x = existingElement.x;
                    textElement.y = existingElement.y;
                    this.overwriteTextElement(existingElement, textElement);
                }else{
                    textElement.setDimensions();
                    textElement.addElement(false,false);
                }

                lastTextElement = textElement;
            }
            if(element.type == "image"){
                let link = element.href;
                if(element.dataLink && !element.dataColor){
                    link = element.dataLink;
                }
                let color = "#000000";
                if(element.dataColor){
                    color = element.dataColor;
                }
                
                let image = new DesignerImage(this.controller, x + w/2, y + h/2, link, true, color, w, h);
                
                if(element.dataColor){
                    if(this.controller.MCI){
                        image.setColor(element.dataColor);
                    }else{
                        image.setColor(this.controller.inkpadColor);
                    }
                }
                if(element.dataRights){
                    let blockedActions = this.convertLegacyPermissions(element.dataRights);
                    image.setBlockedActions(blockedActions);
                }
                image.rotation = rotation;
                
                image.addElement(false,false);
            }
            if(element.type == "rect"){
                if(element.dataOrientation == "vertical"){
                    let temp = w;
                    w = h;
                    h = temp;

                    x -= w/2;
                    y += w/2;
                }
                let rect = new DesignerRect(this.controller, x + w/2, y + h/2, w, h, element.fill, element.dataOrientation);
                rect.addElement(false,false);
            }
        }

        if(!overwriteText){
            if(lastTextElement != null){
                this.overwriteBase(elemenentBaseOverwrite, lastTextElement);
            }else{
                //If no element was overwritten, use the last line from the pattern
                lastTextElement = this.getLastTextFromLegacyData(canvasData);
                if(lastTextElement != null){
                    this.overwriteBase(elemenentBaseOverwrite, lastTextElement);
                }
            }
        }
        

        this.controller.tabInterface.renderTextTab();
        //this.controller.centerContent();
        //this.controller.alignElements();

        if(overwriteText){
            this.loadDateLegacy();
        }

        if(center){
            this.controller.alignElements();
            if(!this.dateIsSet()){
                this.controller.centerContent(false);
            }
        }
    }

    //Load date field from legacy data
    loadDateLegacy(){
        //Date only gets submitted in init!
        //set inital dater - don't re-center later saves
        if(this.dateIsSet()){
            let rectW = this.controller.dater.width*this.controller.config.pixelToMM;
            let rectH = this.controller.dater.height*this.controller.config.pixelToMM;

            let center = this.controller.boundsCenter();
            let centerArea = this.controller.editAreaCenter();
            let area = this.controller.editAreaSize();
            let padding = Math.round(this.controller.cutPadding * this.controller.config.pixelToMM);
            let x = centerArea.x;
            let y = centerArea.y;

            

            if(this.controller.dater.x && this.controller.dater.x != 0){
                x = area.minX + (this.controller.dater.x * this.controller.config.pixelToMM);
                x += rectW/2;
            }
            if(this.controller.dater.y && this.controller.dater.y != 0){
                y = area.minY + (this.controller.dater.y * this.controller.config.pixelToMM);
                y += rectH/2;
            }
            
            //dater fotn size doesnt need to be converted twice, text does
            let date = new DesignerDate(this.controller, x, y, rectW, rectH, this.controller.dater.content, this.controller.dater["font-size"], this.controller.dater.font);
            date.cutSpaces();
            //Set to static
            date.setBlockedActions(["edit", "list", "move", "select"]);
            date.setDimensions();
            date.addElement(false);
            this.controller.dateElement = date;
        }
    }

    //Check if all varaibles to display a date field are set
    dateIsSet(){
        if(this.controller.dater && this.controller.dater.height && this.controller.dater.width){
            return true;
        }
        return false;
    }

    //Convert permission letter list to new array format
    /*
        r = remove
        p = position
        s = size
        t = text
        f = font
        a = alignment
        c = compression (Stauchung)
        u = underline
    */
    convertLegacyPermissions(dataRights: string, isText = false){
        let blockedActions: BlockedActions[] = [];
        let staticCounter = 0;
        for(let letter of dataRights){
            switch(letter){
                case 'r': blockedActions.push("remove"); staticCounter++; break;
                case 'p': blockedActions.push("move"); staticCounter++; break;
                case 's': blockedActions.push("scale"); staticCounter++; break;
                case 't': blockedActions.push("changeText"); break;
                case 'f': blockedActions.push("changeFont"); break;
                case 'a': blockedActions.push("changeAlignment"); break;
                case 'c': blockedActions.push("changeStretch"); break;
                case 'u': blockedActions.push("underline"); break;
            }
        }

        //Set static
        if(staticCounter == 3 && !isText){
            blockedActions = ["edit", "list", "move", "select"];
        }
        return blockedActions;
    }

    //Overwrite text properties with another elements
    overwriteTextElement(existingElement: DesignerText, overwriteElement: DesignerText, histrory = true){
        let sizeBefore = existingElement.getDimensions();
        
        existingElement.setFont(overwriteElement.font, false, false);
        
        existingElement.setSize(overwriteElement.size, false, false);
        
        existingElement.setAlignment(overwriteElement.align);
        existingElement.setBold(overwriteElement.bold);
        existingElement.setItalic(overwriteElement.italic);
        existingElement.setUnderline(overwriteElement.underline);
        existingElement.setDimensions(false);

        existingElement.setBlockedActions( overwriteElement.blockedActions );

        //existingElement.applyAlignment(histrory);
        this.controller.update(true, histrory);
        
    }

    //Restore old texts when loading text pattern
    restoreTexts(oldElements: DesignerElement[]){
        for(let element of this.controller.elements){
            //Skip non text elements
            if(!(element instanceof DesignerText)){
                continue;
            }
            if(element.patternID){
                //Search for the element with the same patternID in the old elemenets
                for(let oldElement of oldElements){
                    if(oldElement instanceof DesignerText && oldElement.patternID == element.patternID){
                        element.text = oldElement.text; 
                    }
                }
            }
        }
    }

    //Load "design patterns"(Gestaltungsmuster) via API
    loadDesignPattern( index: number, artikel_nr: number | string){
        this.controller.API.requestDesignPatterns(artikel_nr).then(
            (data:any) => {
                let pattern = this.getPatternFromIndex(data, index) as TemplateObject;
                if(pattern){
                    this.loadTemplate(pattern);
                    this.controller.startUndoTimer();
                    return true;
                }
                return false;
            }
        );
    }

    //Load "design patterns"(Gestaltungsmuster) via API
    loadUserPattern(artikel_nr: number | string, userID: string, index: number, useCache = true, retry = true){
        this.controller.API.requestUserPatterns(artikel_nr, userID, useCache).then(
            (data:any) => {
                //User Template has special format
                let pattern = this.getPatternFromIndex(data,index) as UserTemplateObject;
                if(!pattern){
                    if(retry){
                        this.loadUserPattern(artikel_nr, userID, index, false, false);
                    }
                    return false;
                }
                let template:TemplateObject = {
                    data: pattern.data.canvas,
                    border: pattern.data.border,
                    pad: pattern.data.pad
                }
                if(pattern){
                    this.loadTemplate(template, true, true, true);
                    this.controller.startUndoTimer();
                    return true;
                }
                return false;
            }
        );
    }

    //Delete "design patterns"(Gestaltungsmuster) via API
    deleteUserPattern(artikel_nr: number | string, userCode: string, ident: number){
        this.controller.API.deleteUserPattern(artikel_nr, userCode, ident).then(
            (data:any) => {
                let message = data.message;
                if(data.success){
                    this.controller.showMessage("success", message);
                    return true;
                }
                return false;
            }
        );
    }

    //Load "font patterns" (Schriftmuster) via API
    loadTextPattern(index: number, overwrite = true, overwriteBorder = true, center = true){
        this.controller.API.requestTextPatterns(this.controller.config.dataset.artikel).then(
            (data:any) => {
                let pattern = this.getPatternFromIndex(data, index) as TemplateObject;
                if(pattern){
                    this.loadTemplate(pattern, overwrite, overwriteBorder, center);
                    this.controller.startUndoTimer();
                    return true;
                }
                return false;
            }
        );
    }

    //Data is sometimes enumerated object, not array, get specific enumerated entry
    getPatternFromIndex(data: any, index : number): TemplateObject | UserTemplateObject | null{
        for(let key in data){
            let pattern = data[key];
            if(key == index.toString()){
                return pattern;
            }
        }
        return null;
    }

    //Load the first pattern available
    loadFirstTextPattern(){
        this.controller.API.requestTextPatterns(this.controller.config.dataset.artikel).then(
            (data:any) => {
                let pattern = data["1"]; 
                this.loadTemplate(pattern);
                return false;
            }
        );
    }
}

export { DesignerPatternController };